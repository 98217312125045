import { Component, OnInit } from '@angular/core';
import { CustomTranslationService, NewsItems, NewsService, PaginationBaseComponent } from 'shared';
import { ModalService } from '../../services/modal.service';
import { Observable } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Consts } from '../../consts';
import { first, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-admin-news',
  templateUrl: './admin-news.component.html',
  styleUrls: ['./admin-news.component.scss']
})
export class AdminNewsComponent extends PaginationBaseComponent implements OnInit {
  public newsItems$: Observable<NewsItems>;

  constructor(private newsService: NewsService,
              private modalService: ModalService,
              public customTranslationService: CustomTranslationService,
              private toastService: ToastService,
              protected activatedRoute: ActivatedRoute,
              protected  router: Router) {
    super(router, activatedRoute);
  }

  protected updateItems(page: number) {
    this.page = page;
    this.newsItems$ = this.newsService.getNews(page - 1, this.itemsPerPage).pipe(shareReplay(1));
    this.subscription?.unsubscribe();
    this.subscription = this.newsItems$.subscribe(newsItems => {
      this.totalNumberOfPages = Math.ceil(newsItems.total / this.itemsPerPage);
    });
  }

  public deleteNews(id: number) {
    this.modalService.showConfirmation('admin.news.deleteTitle', 'admin.news.deleteContent')
      .subscribe(ok => {
        if (ok) {
          this.newsService.deleteNews(id).pipe(first()).subscribe(_ => {
              this.toastService.showToast('admin.news.deletedTitle', 'admin.news.deletedContent');
              this.updateItems(this.page);
            }
            , error => {
              this.modalService.showModal('modal.error', 'admin.news.deletedContentError');
            });
        }
      });
  }

  public async editNews(id: number) {
    await this.router.navigate([Consts.adminRootRoute, Consts.adminCreateNewsRoute, id]);
  }
}
