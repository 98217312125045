import { Injectable } from '@angular/core';
import { Modal } from '../interfaces/modal';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private subject = new Subject<Modal>();

  constructor() {
  }

  public modals(): Observable<Modal> {
    return this.subject.asObservable();
  }

  public showConfirmation(title: string, message: string): Observable<boolean> {
    const confirmationSubject = new Subject<boolean>();
    const modal = {
      title,
      message,
      type: 'confi',
      cancelButtonText: 'modal.cancel',
      okButtonText: 'modal.ok',
      buttonAction: confirmationSubject
    } as Modal;
    this.subject.next(modal);
    return confirmationSubject.pipe(first());
  }

  public showModal(title: string, message: string): Observable<any> {
    const confirmationSubject = new Subject<boolean>();
    const modal = {
      title,
      message,
      okButtonText: 'modal.ok',
      buttonAction: confirmationSubject
    } as Modal;
    this.subject.next(modal);
    return confirmationSubject.pipe(first());
  }
}
