<div class="top-header-margin pt-5 pb-5">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div *ngIf="teamAdminUser$ | async as teamAdmin" class="card">
          <img src="" class="card-img-top" alt="">
          <div *ngIf="teamAdmin.team" class="card-body">
            <h5 class="card-title">{{teamAdmin.team.name}}</h5>
            <div class="container">
              <div class="row">
                <div class="col">
                  {{'team.points' | translate}}
                </div>
                <div class="col">
                  {{teamAdmin.team.points}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!teamAdmin.team" class="card-body">
            <h5 class="card-title">{{'teamAdmin.team.noTeamTitle' | translate}}</h5>
            <p class="card-text">{{'teamAdmin.team.noTeamContent' | translate}}</p>
          </div>
          <div class="card-body">
            <button *ngIf="teamAdmin.team" type="button" class="btn btn-secondary" (click)="updateTeam(teamAdmin.team.id)">{{'teamAdmin.team.update' | translate}}</button>
            <button *ngIf="!teamAdmin.team" type="button" class="btn btn-secondary" (click)="createTeam()">{{'teamAdmin.team.create' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
