<div class="footer-container">
  <div class="container-fluid footer-wrapper">
    <div class="row">
      <div class="col-md-6">
        <div>
          <div><img class="footer-logo-image" src="assets/footer/footer_logo.png"/></div>
          <div class="footer-text">{{'footer.text' | translate}}</div>
        </div>
        <div class="d-flex footer-social-icons">
          <div class="pe-3">
            <a href="https://www.facebook.com/indebolddk" target="_blank"><i class="fab fa-facebook-f"></i></a>
          </div>
          <div class="pe-3">
            <a href="https://www.instagram.com/indebold.dk" target="_blank"><i class="fab fa-instagram"></i></a>
          </div>
          <div class="pe-3">
            <a href="https://www.youtube.com/channel/UCYkSdByFpqRNWWXimcQ4Ovw?disable_polymer=true" target="_blank"><i class="fab fa-youtube"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2 class="footer-recent-posts">{{'footer.recentPosts'| translate}}</h2>
        <div class="d-flex footer-news-container" *ngFor="let news of (newsItems$ | async)?.newsItems">
          <div>
            <a [routerLink]="['news', news.id]">
              <img class="footer-news-image" src="{{news.image}}" alt="news-image">
            </a>
          </div>
          <a class="footer-news-title" [routerLink]="['news', news.id]">
            {{news.title}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid footer-bar-wrapper mb-0">
    <div class="footer-bar">
      <div id="copyright">© Copyright Indebold.dk 2020</div>
    </div>
  </div>
</div>
<a *ngIf="showGoToTop" class="scroll-to-top" (click)="goToTop()">
  <i class="fa fa-angle-up"></i>
</a>
