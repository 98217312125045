import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SignedInGuard } from './signed-in.guard';
import { EUserRole } from '../enums/euser-role';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard extends SignedInGuard implements CanActivate {
  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isSingedIn = await super.canActivate(next, state);
    return isSingedIn && this.authService.hasRole(EUserRole.GlobalAdmin);
  }
}
