import { Component, OnInit } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import {
  SportEvent,
  AuthService,
  SportEventsService,
  CustomTranslationService,
  RegisteredTeam
} from 'shared';
import { ToastService } from '../../services/toast.service';
import { RegisterTeam } from '../../../../../shared/src/lib/interfaces/register-team';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-user-event',
  templateUrl: './user-event.component.html',
  styleUrls: ['./user-event.component.scss']
})
export class UserEventComponent implements OnInit {
  public sportEvent$: Observable<SportEvent>
  private sportEventId: number;

  constructor(public customTranslationService: CustomTranslationService,
              private sportEventsService: SportEventsService,
              private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              public authService: AuthService,
              private toastService: ToastService,
              public utilService: UtilService) {
    const params = this.activatedRoute.snapshot.params;
    if (params.eventId) {
      this.sportEventId = params.eventId as number;
      this.fetchSportsEvent();
    }
  }

  ngOnInit(): void {
  }

  public getEmails(registeredTeams: RegisteredTeam[]) {
    return registeredTeams.map(r => r.contactEmail).join(';');
  }

  public copied($event: any) {
    this.toastService.showToast('toast.copied', 'toast.emails');
  }

  private fetchSportsEvent() {
    this.sportEvent$ = this.sportEventsService.getSportEvent(this.sportEventId).pipe(
      map(sportEvent => {
        return sportEvent;
      }),
      catchError(err => {
        this.modalService.showModal('modal.error', 'global.eventNotFound')
        return of<SportEvent>();
      }));
  }

  public removeTeam(team: RegisteredTeam) {
    this.modalService.showConfirmation('userEvent.removeTeam', 'userEvent.removeTeamText')
      .subscribe(ok => {
        if (ok) {
          let registerTeam = new RegisterTeam();
          registerTeam.teamId = team.teamId;
          registerTeam.registering = false;
          this.sportEventsService.registerTeam(this.sportEventId, registerTeam).pipe(first()).subscribe(_ => {
              this.toastService.showToast('userEvent.removeTeamTitle', 'userEvent.removeTeamModalText');
              this.fetchSportsEvent();
            }
            , error => {
              this.modalService.showModal('modal.error', 'userEvent.removeTeamError');
            });
        }
      });
  }
}
