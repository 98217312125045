<ng-template #content>
  <div class="modal-header">
    <h5 class="modal-title">{{openModal?.title | translate}}</h5>
  </div>
  <div class="modal-body">
    {{openModal?.message | translate}}
  </div>
  <div class="modal-footer">
    <button *ngIf="openModal?.cancelButtonText" type="button" class="btn btn-secondary" (click)="onButtonClicked(false)">{{openModal.cancelButtonText | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="onButtonClicked(true)">{{openModal?.okButtonText | translate}}</button>
  </div>
</ng-template>
