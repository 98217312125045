import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { TeamsService, EventShopUser, SearchUser, UsersService, AuthService } from 'shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-team-admin-add-admin',
  templateUrl: './team-admin-add-admin.component.html',
  styleUrls: ['./team-admin-add-admin.component.scss']
})
export class TeamAdminAddAdminComponent implements OnInit {
  public managers: Observable<EventShopUser[]>;

  public user: EventShopUser;
  public userSearch: string;
  public foundUsers: SearchUser[];
  private subscription = new Subscription();
  private readonly teamId: number;
  @ViewChild('modal') modalElement: ElementRef;
  private ngbModalRef: NgbModalRef;

  constructor(private teamsService: TeamsService, private authService: AuthService, private usersService: UsersService, private  modalService: ModalService, private activatedRoute: ActivatedRoute, private ngbModal: NgbModal) {
    this.subscription.add(this.authService.userChanged$.subscribe(u => this.user = u));
    const params = this.activatedRoute.snapshot.params;
    if (params.teamId) {
      this.teamId = params.teamId as number;
      this.managers = this.teamsService.getManagers(this.teamId);
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public removeManager(userId: number) {
    this.teamsService.removeManager(this.teamId, userId)
      .pipe(first())
      .subscribe(_ => this.managers = this.teamsService.getManagers(this.teamId));
  }

  public addManager(userId: number) {
    this.cancelButtonPressed();
    this.teamsService.addManager(this.teamId, userId).pipe(first())
      .subscribe(_ => this.managers = this.teamsService.getManagers(this.teamId));
  }

  public searchUsers() {
    this.usersService.searchUsers({searchString: this.userSearch})
      .pipe(first()).subscribe(r => {
      this.foundUsers = r.searchResult;
      this.ngbModalRef = this.ngbModal.open(this.modalElement, {size: 'xl', scrollable: true, centered: true})
    });
  }

  public cancelButtonPressed() {
    this.ngbModalRef.close();
  }
}
