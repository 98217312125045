import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { NewsCategoriesService, NewsCategory } from 'shared';
import { Router } from '@angular/router';
import { Consts } from '../../consts';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-admin-news-categories',
  templateUrl: './admin-news-categories.component.html',
  styleUrls: ['./admin-news-categories.component.scss']
})
export class AdminNewsCategoriesComponent implements OnInit {
  public newsCategories$: Observable<NewsCategory[]>;

  constructor(private newsCategoriesService: NewsCategoriesService,
              private modalService: ModalService,
              private toastService: ToastService,
              private router: Router) {
    this.updateNewsCategories();
  }

  ngOnInit() {
  }

  public async deleteNewsCategory(id: number) {
    await this.modalService.showConfirmation('admin.newsCategories.deleteTitle', 'admin.newsCategories.deleteContent')
      .subscribe(ok => {
        if (ok) {
          this.newsCategoriesService.deleteNewsCategory(id).pipe(first()).subscribe(_ => {
              this.toastService.showToast('admin.newsCategories.deletedTitle', 'admin.newsCategories.deletedContent');
              this.updateNewsCategories();
            }
            , error => {
              this.modalService.showModal('modal.error', 'admin.newsCategories.deletedContentError');
            });
        }
      });
  }

  public async editNewsCategory(id: number) {
    await this.router.navigate([Consts.adminRootRoute, Consts.adminCreateEditNewsCategoryRoute, id]);
  }

  private updateNewsCategories() {
    this.newsCategories$ = this.newsCategoriesService.getNewsCategories();
  }
}
