<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'signUpUser.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="userForm" (ngSubmit)="signUp()">
        <div class="form-group">
          <label class="form-label" for="username">{{'signUpUser.username' | translate}}*</label>
          <input id="username" class="form-control content-input" formControlName="username" required (change)="haveToCheckUsername()" [ngClass]="userForm.controls.username.valid ? 'is-valid' : 'is-invalid'" (ngModelChange)="validateUsername()"/>
          <div class="password-invalid" *ngIf="usernameValidation && !usernameValidation.special">
            {{'signUpUser.usernameSpecial' | translate}}
          </div>
          <div class="password-invalid" *ngIf="usernameValidation && !usernameValidation.spacing">
            {{'signUpUser.spacing' | translate}}
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="form-button btn text-uppercase" type="button" name="check" (click)="checkUsername()" [disabled]="!this.utilService.usernameValid(usernameValidation)">
            {{'signUpUser.checkUsername' | translate}}
          </button>
        </div>
        <div class="form-group">
          <label class="form-label" for="password">{{'signUpUser.password' | translate}}*</label>
          <input id="password" class="form-control content-input" formControlName="password" required type="password" (ngModelChange)="validatePassword()" [ngClass]="userForm.controls.password.valid ? 'is-valid' : 'is-invalid'"/>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.length">
            {{'signUpUser.length' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.capitalLetter">
            {{'signUpUser.capitalLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.smallLetter">
            {{'signUpUser.smallLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.digit">
            {{'signUpUser.digit' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.special">
            {{'signUpUser.special' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.spacing">
            {{'signUpUser.spacing' | translate}}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="repeatPassword">{{'signUpUser.repeatPassword' | translate}}*</label>
          <input id="repeatPassword" class="form-control content-input" formControlName="repeatPassword" required type="password" (ngModelChange)="validateRepeatedPassword()" [ngClass]="userForm.controls.repeatPassword.valid ? 'is-valid' : 'is-invalid'"/>
          <div class="password-invalid" *ngIf="userForm.controls.repeatPassword.invalid && (userForm.controls.repeatPassword.dirty || userForm.controls.repeatPassword.touched)">
            {{'signUpUser.notMatching' | translate}}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="firstName">{{'user.form.firstName' | translate}}*</label>
          <input id="firstName" class="form-control content-input" formControlName="firstName" required [ngClass]="userForm.controls.firstName.valid ? 'is-valid' : 'is-invalid'"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="lastName">{{'user.form.lastName' | translate}}*</label>
          <input id="lastName" class="form-control content-input" formControlName="lastName" required [ngClass]="userForm.controls.lastName.valid ? 'is-valid' : 'is-invalid'"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="email">{{'user.form.email' | translate}}*</label>
          <input id="email" class="form-control content-input" formControlName="email" required [ngClass]="userForm.controls.email.valid ? 'is-valid' : 'is-invalid'"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="phoneNumber">{{'user.form.phoneNumber' | translate}}*</label>
          <input id="phoneNumber" class="form-control content-input" formControlName="phoneNumber" type="text" maxlength="8" required [ngClass]="userForm.controls.phoneNumber.valid ? 'is-valid' : 'is-invalid'"/>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="form-button btn text-uppercase" type="submit" name="update">
            {{'signUpUser.create' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
