<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="item-header">
        <h5>{{getHeaderText() | translate}}</h5>
      </div>
      <form (submit)="updateTeam()" [formGroup]="teamForm">
        <div class="form-group">
          <label class="form-label" for="name">{{'teamAdmin.team.name' | translate}}</label>
          <input id="name" class="form-control" formControlName="name" required [ngClass]="teamForm.controls.name.valid ? 'is-valid' : 'is-invalid'"/>
        </div>
        <div class="form-group editor-container">
          <label class="form-label" for="content">{{'admin.news.content' | translate}}</label>
          <div class="editor">
            <quill-editor id="description" formControlName="description"  [sanitize]="true" ></quill-editor>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="playersCategory">{{'global.playersCategory' | translate}}</label>
          <select id="playersCategory" class="form-select" formControlName="playersCategory" required [ngClass]="teamForm.controls.playersCategory.valid ? 'is-valid' : 'is-invalid'">
            <option *ngFor="let playersCategory of getPlayersCategories()" value="{{playersCategory}}">{{'global.playersCategoryItem.' + playersCategory | translate}}</option>
          </select>
        </div>
        <div formGroupName="location">
          <div class="form-group">
            <label class="form-label" for="streetOne">{{'teamAdmin.team.streetOne' | translate}}</label>
            <input id="streetOne" class="form-control" formControlName="streetOne" type="text" required [ngClass]="teamForm.controls.location.get('streetOne').valid ? 'is-valid' : 'is-invalid'"/>
          </div>
          <div class="form-group">
            <label class="form-label" for="streetTwo">{{'teamAdmin.team.streetTwo' | translate}}</label>
            <input id="streetTwo" class="form-control" formControlName="streetTwo" type="text" [ngClass]="teamForm.controls.location.get('streetTwo').valid ? 'is-valid' : 'is-invalid'"/>
          </div>
          <div class="form-group">
            <div class="form-row">
              <div class="col-md-4">
                <label class="form-label" for="postalCode">{{'teamAdmin.team.postalCode' | translate}}</label>
                <input id="postalCode" class="form-control" formControlName="postalCode" type="number" min="0" required [ngClass]="teamForm.controls.location.get('postalCode').valid ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="col-md-8">
                <label class="form-label" for="city">{{'teamAdmin.team.city' | translate}}</label>
                <input id="city" class="form-control" formControlName="city" type="text" required [ngClass]="teamForm.controls.location.get('city').valid ? 'is-valid' : 'is-invalid'"/>
              </div>
            </div>
          </div>
        </div>
        <app-file-selection-component [imageUrl]="this.imageUrl" (file)="onFileChanged($event)"></app-file-selection-component>
        <div class="form-group mt-3">
          <button class="form-button rounded text-uppercase" [disabled]="!teamForm.valid" type="submit" name="submitButton">
            {{getButtonText() | translate}}
          </button>
        </div>
      </form>
    </div>
    <!--      <div class="col-md-2">-->
    <!--        <app-file-selection-component [imageUrl]="this.imageUrl" (imageData)="imageSelected($event)"></app-file-selection-component>-->
    <!--      </div>-->
  </div>
</div>
