import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEditNews, NewsCategoriesService, NewsCategory, NewsService } from 'shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { Consts } from '../../consts';
import { forkJoin, Observable, of } from 'rxjs';
import lodash from 'lodash';
import { catchError, first, switchMap } from 'rxjs/operators';
import { ContentChange } from 'ngx-quill';

@Component({
  selector: 'app-admin-create-news',
  templateUrl: './admin-create-edit-news.component.html',
  styleUrls: ['./admin-create-edit-news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminCreateEditNewsComponent implements OnInit {
  public newsForm: FormGroup = new FormGroup({
    title: new FormControl(undefined, [Validators.required]),
    content: new FormControl(undefined, [Validators.required])
  });
  public newsCategories$: Observable<NewsCategory[]>;
  public imageUrl = '';
  private readonly id?: number;
  private readonly creating: boolean;
  private selectedCategories: NewsCategory[] = [];
  private file: File;

  constructor(private activatedRoute: ActivatedRoute,
              private newsService: NewsService,
              private modalService: ModalService,
              private toastService: ToastService,
              private router: Router,
              private newsCategoriesService: NewsCategoriesService) {
    const params = this.activatedRoute.snapshot.params;
    this.creating = true;
    if (params.newsId) {
      this.id = params.newsId as number;
      this.creating = false;
      this.newsService.getNewsItem(params.newsId as number).pipe(first()).subscribe(news => {
        this.newsForm.patchValue(news);
        this.selectedCategories = news.categories;
        this.imageUrl = `${news.image}?updated=${new Date().getTime()}`;
      }, error => {
        this.modalService.showModal('modal.error', 'global.newsNotFound')
          .subscribe(_ => this.router.navigate([Consts.adminRootRoute, Consts.adminNewsRoute]));
      });
    }
    this.newsCategories$ = this.newsCategoriesService.getNewsCategories();
  }

  ngOnInit() {
  }

  public getButtonText(): string {
    if (this.creating) {
      return 'admin.news.create';
    } else {
      return 'admin.news.update';
    }
  }

  public getHeaderText(): string {
    if (this.creating) {
      return 'admin.news.createNews';
    } else {
      return 'admin.news.updateNews';
    }
  }

  public updateNews() {
    const data = this.newsForm.value as CreateEditNews;
    data.categories = this.selectedCategories;

    if (this.creating) {
      this.newsService.createNews(data).pipe(
        catchError(_ => this.modalService.showModal('modal.error', 'admin.news.createNewsError')),first(),
        switchMap(newsItem => {
          {
            if (this.file) {
              return this.newsService.setNewsImage(newsItem.id, this.file).pipe(first());
            }

            return of({});
          }
        }))
        .subscribe(_ => {
          this.router.navigate([Consts.adminRootRoute, Consts.adminNewsRoute])
        });
    } else {
      forkJoin([
        this.newsService.updateNews(this.id, data),
        this.newsService.setNewsImage(this.id, this.file).pipe(first())
      ]).pipe(catchError(_ => this.modalService.showModal('modal.error', 'admin.news.updatedContentError')),
        first())
        .subscribe(_ => this.router.navigate([Consts.adminRootRoute, Consts.adminNewsRoute]));
    }
  }

  public onCategoryChange(category: NewsCategory, $event) {
    if ($event.currentTarget.checked) {
      this.selectedCategories.push(category);
    } else {
      lodash.remove(this.selectedCategories, i => {
        return i.id === category.id;
      });
    }
  }

  public isSelected(category: NewsCategory) {
    return lodash.some(this.selectedCategories, {id: category.id});
  }

  public onFileChanged(file: File) {
    this.file = file;
  }
}
