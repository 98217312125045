export class Consts {
  public static readonly categoryNewsRoute = 'news/category';
  public static readonly newsRoute = 'news';
  public static readonly events = 'events'
  public static readonly rankings = 'rankings'
  public static readonly contact = 'contact'
  public static readonly livescore = 'livescore'
  public static readonly team = 'community'
  public static readonly community = 'community'
  public static readonly userSignUpRoute = 'user-sign-up';
  public static readonly userSignIn = 'user-sign-in';
  public static readonly userChangePasswordRoute = 'user-change-password';
  public static readonly userResetPasswordLinkRoute = 'user-reset-password-link';
  public static readonly userResetPasswordRoute = 'user-reset-password';

  public static readonly createEditEvent = 'create-event';
  public static readonly addRemoveEventAdmin = 'event-admins';

  public static readonly editUser = 'edit-user'
  public static readonly userRoot = 'user'
  public static readonly userEvents = 'events'
  public static readonly userEvent = 'event'
  public static readonly userTeams = 'teams'

  public static readonly adminRootRoute = 'admin';
  public static readonly adminNewsRoute = 'news';
  public static readonly adminCreateNewsRoute = 'create-news';
  public static readonly adminNewsCategoriesRoute = 'news-categories';
  public static readonly adminCreateEditNewsCategoryRoute = 'create-news-category';
  public static readonly adminAddTeamPointsRoute = 'add-team-points';

  public static readonly teamAdminRootRoute = 'team';
  public static readonly teamAdminTeamsRoute = 'teams';
  public static readonly teamAdminCreateEditTeamRoute = 'create-team';
  public static readonly teamAdminAddAdminRoute = 'add-admin';

  public static readonly notFound = 'not-found';
}
