import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrlToken, contentProviderToken } from '../injection-tokens';

@Injectable()
export class ContentProviderInterceptor implements HttpInterceptor {

  constructor(@Inject(contentProviderToken) private contentProvider: string, @Inject(apiUrlToken) private apiUrl: string) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.apiUrl)) {
      request = request.clone({
        setHeaders: {
          'X-Eventshop-ContentProvider': this.contentProvider
        }
      });
    }

    return next.handle(request);
  }
}
