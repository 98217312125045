<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <div *ngFor="let news of (news$ | async)?.newsItems, first as isFirst">
        <div *ngIf="isFirst" class="first-news-container" [style.background-image]="'url(' + news.image + ')'">
          <div class="first-news-header">
            <div class="news-details">
              <span class="news-date">
                {{news.createdDate | date: 'longDate' : 'IST' : customTranslationService.getLang() | titlecase}}
				  		</span>
            </div>
            <h2>
              <a [routerLink]="[getNewsRoute(), news.id]">{{news.title}}</a>
            </h2>
          </div>
        </div>
        <app-news-list-item *ngIf="isFirst === false" [news]="news"></app-news-list-item>
      </div>
    </div>
    <div class="col-md-4">
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>
