import { Inject, Injectable } from '@angular/core';
import { EventShopUser } from '../interfaces/eventShopUser';
import { Observable } from 'rxjs';
import { apiUrlToken } from '../injection-tokens';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SearchUsersResponse } from '../interfaces/search-users-response';
import { SearchUsersRequest } from '../interfaces/search-users-request';
import { SignUpUser } from '../interfaces/sign-up-user';
import { ChangePassword } from '../interfaces/change-password';
import { ResetPasswordLink } from '../interfaces/reset-password-link';
import { ResetPassword } from '../interfaces/reset-password';
import { SignIn } from '../interfaces/sign-in';
import { TokenResponse } from '../interfaces/token-response';
import { RefreshToken } from '../interfaces/refresh-token';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly urlPrefix = 'api/Users';

  constructor(@Inject(apiUrlToken) private apiUrl: string, private httpClient: HttpClient) {
  }

  public updateUser(user: EventShopUser): Observable<EventShopUser> {
    return this.httpClient.put<EventShopUser>(`${this.apiUrl}/${this.urlPrefix}/UpdateUserInfo`, user);
  }

  public searchUsers(searchRequest: SearchUsersRequest): Observable<SearchUsersResponse> {
    return this.httpClient.post<SearchUsersResponse>(`${this.apiUrl}/${this.urlPrefix}/SearchUsers/`, searchRequest);
  }

  public signUp(signUpUser: SignUpUser): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Users/SignUp`, signUpUser);
  }

  public checkUsername(signUpUser: SignUpUser): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(`${this.apiUrl}/api/Users/CheckUsername`, signUpUser, {observe: 'response'});
  }

  public changePassword(changePassword: ChangePassword): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Users/ChangeUserPassword`, changePassword);
  }

  public resetPasswordLink(resetPasswordLink: ResetPasswordLink): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Users/SendResetPasswordLink`, resetPasswordLink);
  }

  public resetPassword(resetPassword: ResetPassword): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Users/ResetUserPassword`, resetPassword);
  }
}
