import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eventShop-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({
        opacity: .8,
      })),
      state('hide', style({
        opacity: 0,
      })),
      transition('* => show', [
        animate('0.25s')
      ]),
      transition('show => hide', [
        animate('0.25s')
      ]),
      transition('hide => show', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class LoaderComponent implements OnInit, OnDestroy {
  public isLoading: boolean
  public showBlock: boolean;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.isLoading.subscribe(value => {
      if (value) {
        this.showBlock = true;
      }
      this.isLoading = value;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onAnimationEventDone(event: AnimationEvent) {
    if (event.toState === 'hide') {
      this.showBlock = false
    }
  }
}
