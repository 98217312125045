import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-selection-component',
  templateUrl: './file-selection.component.html',
  styleUrls: ['./file-selection.component.scss']
})
export class FileSelectionComponent implements OnInit {
  @Input() public imageUrl: string;
  @Output() public file = new EventEmitter<File>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public onFileChange($event) {
    if ($event.target.files && $event.target.files[0]) {
      const file = $event.target.files[0] as File;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imageUrl = event.target.result as string;
      };

      this.file.emit(file);
    }
  }
}
