<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'userSignIn.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="userForm" (ngSubmit)="signIn()">
        <div *ngIf="showError" class="text-danger">{{'userSignIn.error' | translate}}</div>
        <div class="form-group">
          <label class="form-label" for="username">{{'userSignIn.username' | translate}}*</label>
          <input id="username" class="form-control content-input" formControlName="username" required/>
        </div>
        <div class="form-group">
          <label class="form-label" for="password">{{'userSignIn.password' | translate}}*</label>
          <input id="password" class="form-control content-input" formControlName="password" required type="password" />
          <div>
            <span>{{'userSignIn.resetPassword' | translate}} </span> <a id="resend-confirmation" [routerLink]="['/user-reset-password-link']" >{{'userSignIn.resetPasswordLink' | translate}}</a>
          </div>
        </div>
        <div class="form-group d-flex justify-content-center mt-4">
          <button type="submit" class="form-button btn text-uppercase font-italic" >{{'userSignIn.login' | translate}}</button>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4">
        <button type="submit" class="form-button btn text-uppercase font-italic" [routerLink]="['/user-sign-up']">{{'userSignIn.createNewUser' | translate}}</button>
      </div>
    </div>
  </div>
</div>
