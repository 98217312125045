import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { SportEventsService, EventShopUser, SearchUser, AuthService, UsersService, SportEvent} from 'shared';
import { first} from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-remove-event-admins',
  templateUrl: './add-remove-event-admins.component.html',
  styleUrls: ['./add-remove-event-admins.component.scss']
})
export class AddRemoveEventAdminsComponent implements OnInit {
  public adminUsers$: Observable<EventShopUser[]>;

  public user: EventShopUser;
  public userSearch: string;
  public foundUsers: SearchUser[];
  private subscription = new Subscription();
  private eventId: number;
  @ViewChild('modal') modalElement: ElementRef;
  private ngbModalRef: NgbModalRef;

  constructor(private sportEventsService: SportEventsService, private authService: AuthService, private usersService: UsersService, private ngbModal: NgbModal, private activatedRoute: ActivatedRoute) {
    this.subscription.add(this.authService.userChanged$.subscribe(u => this.user = u));
    const params = this.activatedRoute.snapshot.params;
    if (params.eventId) {
      this.eventId = params.eventId as number;
      this.adminUsers$ = this.sportEventsService.getAdmins(this.eventId);
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public removeUser(userId: number) {
    this.sportEventsService.removeAdmin(this.eventId, userId)
      .pipe(first())
      .subscribe(_ => this.adminUsers$ = this.sportEventsService.getAdmins(this.eventId));
  }

  public addUser(userId: number) {
    this.cancelButtonPressed();
    this.sportEventsService.addAdmin(this.eventId, userId).pipe(first())
      .subscribe(_ => this.adminUsers$ = this.sportEventsService.getAdmins(this.eventId));
  }

  public searchUsers() {
    this.usersService.searchUsers({searchString: this.userSearch})
      .pipe(first()).subscribe(r => {
      this.foundUsers = r.searchResult;
      this.ngbModalRef = this.ngbModal.open(this.modalElement, {size: 'xl', scrollable: true, centered: true})
    });
  }

  public cancelButtonPressed() {
    this.ngbModalRef.close();
  }
}
