<div class="top-header-margin ">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div>
          <h1 class="header">{{getHeaderText() | translate}}</h1>
        </div>
        <form (submit)="updateEvent()" [formGroup]="eventForm">
          <div class="form-section">
            <h5>{{'events.form.title' | translate}}</h5>
            <div class="form-group">
              <label class="form-label" for="name">{{'events.form.name' | translate}}</label>
              <input id="name" class="form-control" formControlName="name" type="text" required [ngClass]="eventForm.controls.name.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label for="sportEventType">{{'global.indoorSoccerEventTier' | translate}}</label>
              <select id="sportEventType" class="form-select" formControlName="indoorSoccerEventTier" required [ngClass]="eventForm.controls.indoorSoccerEventTier.valid ? 'is-valid' : 'is-invalid'">
                <option *ngFor="let sportEventType of getIndoorSoccerEventTier()" value="{{sportEventType}}">{{'global.indoorSoccerEventTierItem.' + sportEventType | translate}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="indoorFootballEventType">{{'global.indoorFootballEventType' | translate}}</label>
              <select id="indoorFootballEventType" class="form-select" formControlName="indoorFootballEventType" required [ngClass]="eventForm.controls.indoorFootballEventType.valid ? 'is-valid' : 'is-invalid'">
                <option *ngFor="let indoorFootballEventType of getIndoorFootballEventTypes()" value="{{indoorFootballEventType}}">{{'global.indoorFootballEventTypeItem.' + indoorFootballEventType | translate}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="minPlayers">{{'events.form.minPlayers' | translate}}</label>
              <input id="minPlayers" class="form-control" formControlName="minPlayers" type="number" min="0" required [ngClass]="eventForm.controls.minPlayers.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="maxPlayers">{{'events.form.maxPlayers' | translate}}</label>
              <input id="maxPlayers" class="form-control" formControlName="maxPlayers" type="number" min="0" required [ngClass]="eventForm.controls.maxPlayers.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="playersCategory">{{'global.playersCategory' | translate}}</label>
              <select id="playersCategory" class="form-select" formControlName="playersCategory" required [ngClass]="eventForm.controls.playersCategory.valid ? 'is-valid' : 'is-invalid'">
                <option *ngFor="let playersCategory of getPlayersCategories()" value="{{playersCategory}}">{{'global.playersCategoryItem.' + playersCategory | translate}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="startDate">{{'events.form.startDate' | translate}}</label>
              <input id="startDate" class="form-control" formControlName="startDate" min="{{today() | date: 'yyyy-MM-dd'}}" type="date" required [ngClass]="eventForm.controls.startDate.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="endDate">{{'events.form.endDate' | translate}}</label>
              <input id="endDate" class="form-control" formControlName="endDate" type="date" min="{{today() | date: 'yyyy-MM-dd'}}" required [ngClass]="eventForm.controls.endDate.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="firstPrize">1. {{'event.prize' | translate}}</label>
              <input id="firstPrize" class="form-control" formControlName="firstPrize" type="text"/>
              <small class="form-text text-muted">
                {{'events.form.prizeHelpText' | translate}}
              </small>
            </div>
            <div class="form-group">
              <label class="form-label" for="secondPrize">2. {{'event.prize' | translate}}</label>
              <input id="secondPrize" class="form-control" formControlName="secondPrize" type="text"/>
              <small class="form-text text-muted">
                {{'events.form.prizeHelpText' | translate}}
              </small>
            </div>
            <div class="form-group">
              <label class="form-label" for="thirdPrize">3. {{'event.prize' | translate}}</label>
              <input id="thirdPrize" class="form-control" formControlName="thirdPrize" type="text"/>
              <small class="form-text text-muted">
                {{'events.form.prizeHelpText' | translate}}
              </small>
            </div>
            <div class="form-group">
              <label class="form-label" for="fourthPrize">4. {{'event.prize' | translate}}</label>
              <input id="fourthPrize" class="form-control" formControlName="fourthPrize" type="text"/>
              <small class="form-text text-muted">
                {{'events.form.prizeHelpText' | translate}}
              </small>
            </div>
            <div class="form-group">
              <label class="form-label" for="fifthPrize">5. {{'event.prize' | translate}}</label>
              <input id="fifthPrize" class="form-control" formControlName="fifthPrize" type="text"/>
              <small class="form-text text-muted">
                {{'events.form.prizeHelpText' | translate}}
              </small>
            </div>
            <div class="form-group">
              <label class="form-label" for="sixthPrize">6. {{'event.prize' | translate}}</label>
              <input id="sixthPrize" class="form-control" formControlName="sixthPrize" type="text"/>
              <small class="form-text text-muted">
                {{'events.form.prizeHelpText' | translate}}
              </small>
            </div>
            <div class="form-group">
              <label class="form-label" for="venue">{{'events.form.venue' | translate}}</label>
              <input id="venue" class="form-control" formControlName="venue" type="text" required v [ngClass]="eventForm.controls.venue.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div formGroupName="location">
              <div class="form-group">
                <label class="form-label" for="streetOne">{{'events.form.streetOne' | translate}}</label>
                <input id="streetOne" class="form-control" formControlName="streetOne" type="text" required [ngClass]="eventForm.controls.location.get('streetOne').valid ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="streetTwo">{{'events.form.streetTwo' | translate}}</label>
                <input id="streetTwo" class="form-control" formControlName="streetTwo" type="text" [ngClass]="eventForm.controls.location.get('streetTwo').valid ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-md-4">
                    <label class="form-label" for="postalCode">{{'events.form.postalCode' | translate}}</label>
                    <input id="postalCode" class="form-control" formControlName="postalCode" type="text" maxlength="4" min="0" required [ngClass]="eventForm.controls.location.get('postalCode').valid ? 'is-valid' : 'is-invalid'"/>
                  </div>
                  <div class="col-md-8">
                    <label class="form-label" for="city">{{'events.form.city' | translate}}</label>
                    <input id="city" class="form-control" formControlName="city" type="text" required [ngClass]="eventForm.controls.location.get('city').valid ? 'is-valid' : 'is-invalid'"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-section">
            <h5>{{'event.signUp' | translate}}</h5>
            <div class="form-group">
              <label>{{'event.signUp' | translate}}</label>
              <div class="form-row me-0 ms-0">
                <div class="form-group">
                  <div class="form-check form-check-inline" *ngFor="let eventSignUpType of getEventSignUpTypes()">
                    <input class="form-check-input" type="checkbox" value="" id="{{eventSignUpType}}" formControlName="{{eventSignUpType}}" [ngClass]="eventSignUpTypesValid() ? 'is-valid' : 'is-invalid'">
                    <label class="form-check-label" for="{{eventSignUpType}}">{{'global.eventSignUpTypeItem.' + eventSignUpType | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="inviteOnlyEvent" formControlName="inviteOnlyEvent">
                <label class="form-check-label" for="inviteOnlyEvent">
                  {{'events.form.inviteOnlyEvent' | translate}}
                </label>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="entryFee">{{'global.entryFee' | translate}}</label>
              <input id="entryFee" class="form-control" formControlName="entryFee" type="number" step="any"/>
            </div>
            <div class="form-group">
              <div class="form-row me-0 ms-0">
                <div class="form-group">
                  <div class="form-check form-check-inline" *ngFor="let paymentMethod of getPaymentMethods()">
                    <input class="form-check-input" type="checkbox" value="" id="{{paymentMethod}}" formControlName="{{paymentMethod}}" [ngClass]="paymentMethodsValid() ? 'is-valid' : 'is-invalid'">
                    <label class="form-check-label" for="{{paymentMethod}}">{{'global.paymentMethodItem.' + paymentMethod | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div formGroupName="bankAccount">
                <div class="form-row">
                  <div class="col-md-4">
                    <label class="form-label" for="registrationNumber">{{'events.form.registrationNumber' | translate}}</label>
                    <input id="registrationNumber" class="form-control" formControlName="registrationNumber" type="text" min="0" maxlength="4" [ngClass]="eventForm.controls.bankAccount.get('registrationNumber').valid ? 'is-valid' : 'is-invalid'"/>
                  </div>
                  <div class="col-md-8">
                    <label class="form-label" for="accountNumber">{{'events.form.accountNumber' | translate}}</label>
                    <input id="accountNumber" class="form-control" formControlName="accountNumber" type="text" min="0" maxlength="10" [ngClass]="eventForm.controls.bankAccount.get('accountNumber').valid ? 'is-valid' : 'is-invalid'"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="signUpDeadline">{{'event.signUpDeadline' | translate}}</label>
              <input id="signUpDeadline" class="form-control" formControlName="signUpDeadline" min="{{today() | date: 'yyyy-MM-dd'}}" type="date" required [ngClass]="eventForm.controls.signUpDeadline.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="maxNumberOfTeams">{{'event.maxNumberOfTeams' | translate}}</label>
              <input id="maxNumberOfTeams" class="form-control" formControlName="maxNumberOfTeams"  min="0" type="number" placeholder="{{'event.maxNumberOfTeamsNoLimit' | translate}}"/>
            </div>
          </div>
          <div class="form-section">
            <h5>{{'event.contact' | translate}}</h5>
            <div class="form-group">
              <label class="form-label" for="organizer">{{'event.organizer' | translate}}</label>
              <input id="organizer" class="form-control" formControlName="organizer" type="text" required [ngClass]="eventForm.controls.organizer.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="contactPerson">{{'event.contactName' | translate}}</label>
              <input id="contactPerson" class="form-control" formControlName="contactPerson" type="text" required [ngClass]="eventForm.controls.contactPerson.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="contactEmail">{{'event.contactEmail' | translate}}</label>
              <input id="contactEmail" class="form-control" formControlName="contactEmail" type="text" required [ngClass]="eventForm.controls.contactEmail.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="contactPhone">{{'event.contactPhone' | translate}}</label>
              <input id="contactPhone" class="form-control" formControlName="contactPhone" type="text" maxlength="8" required [ngClass]="eventForm.controls.contactPhone.valid ? 'is-valid' : 'is-invalid'"/>
            </div>
            <div class="form-group">
              <label class="form-label" for="contactWebsite">{{'event.contactWebsite' | translate}}</label>
              <input id="contactWebsite" class="form-control" formControlName="contactWebsite" type="text"/>
            </div>
            <div class="form-section">
              <h5>{{'event.other' | translate}}</h5>
              <div class="form-group">
                <label class="form-label" for="comment">{{'event.comment' | translate}}</label>
                <textarea id="comment" class="form-control" formControlName="comment" rows="5"></textarea>
                <small class="form-text text-muted">
                  {{'events.form.commentLength' | translate}}
                </small>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="form-button btn text-uppercase" [disabled]="!eventForm.valid || !customFieldsValid()" type="submit" name="submitButton">
              {{getButtonText() | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
