<div class="container-fluid mt-0 mb-0">
  <div class="row logo-wrapper">
    <div class="col-md-6">
      <div class="header-logo">
        <a [routerLink]="'/'">
          <img class="image align-middle" src="/assets/header/logo.png"/>
        </a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="header-ad">
        <a href="https://fc-glesborg.safeticket.dk/dm2025" target="_blank">
          <img class="image" src="/assets/header/728x90ads.png"/>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="sticky-top">
  <div class="nav-container container-fluid nav-sticky-container mt-0">
    <nav class="navbar navbar-expand-md navbar-dark nav-wrapper pe-0 ">
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon custom-navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-auto">
          <li class="nav-item" *ngFor="let menuEntry of mainMenu">
            <a class="nav-link" *ngIf="menuEntry.route" [attr.data-bs-toggle]="(breakpointObserver.ipad$ | async).matches ? 'collapse' : null" [attr.data-bs-target]="(breakpointObserver.ipad$ | async).matches ? '#navbarNavDropdown' : null " [routerLink]="menuEntry.route"><span [class]="menuEntry.selected ? 'nav-link-active' : ''">{{menuEntry.translationKey | translate}} </span></a>
            <a class="nav-link" rel="noopener" rel="noreferrer" *ngIf="menuEntry.link" href="{{menuEntry.link}}" target="_blank" ><span [class]="menuEntry.selected ? 'nav-link-active' : ''">{{menuEntry.translationKey | translate}}</span></a>
          </li>
<!--          <li class="nav-item">-->
<!--&lt;!&ndash;            <a class="nav-link" attr.data-bs-toggle="collapse" [attr.data-bs-target]="(breakpointObserver.ipad$ | async).matches ? '#navbarNavDropdown' : null " href="{{menuEntry.link}}" target="_blank"><span [class]="menuEntry.selected ? 'nav-link-active' : ''">{{menuEntry.translationKey | translate}}</span></a>&ndash;&gt;-->
<!--          </li>-->
        </ul>
        <ul class="navbar-nav">
          <li *ngIf="!(authService.signedInChanged$ | async)" class="nav-item dropdown">
            <a class="nav-link text-uppercase" [attr.data-bs-toggle]="(breakpointObserver.ipad$ | async).matches ? 'collapse' : null" [attr.data-bs-target]="(breakpointObserver.ipad$ | async).matches ? '#navbarNavDropdown' : null " [routerLink]="signInUrl()" ><span>{{'header.menuItems.signIn' | translate}}</span></a>
          </li>
          <li *ngIf="(authService.signedInChanged$  | async)" ngbDropdown class="nav-item dropdown" placement="bottom">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-uppercase" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-expanded="false" role-menu [attr.data-bs-target]="(breakpointObserver.ipad$ | async).matches ? '#navbarNavDropdown' : null">
              {{'header.menu' | translate }}
            </a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a *ngFor="let menuEntry of userMenu" class="dropdown-item" [routerLink]="menuEntry.route">{{menuEntry.translationKey | translate}}</a>
              <div class="dropdown-divider"></div>
              <a *ngFor="let menuEntry of defaultUserMenu" class="dropdown-item" [routerLink]="menuEntry.route">{{menuEntry.translationKey | translate}}</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">{{'header.menuItems.signOut' | translate}}</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
