import { Component, Input, OnInit } from '@angular/core';
import { CustomTranslationService, News, NewsService } from 'shared';
import { Consts } from '../../consts';

@Component({
  selector: 'app-news-list-item',
  templateUrl: './news-list-item.component.html',
  styleUrls: ['./news-list-item.component.scss']
})
export class NewsListItemComponent implements OnInit {
  @Input() news: News

  constructor(public newsService: NewsService, public customTranslationService: CustomTranslationService) {
  }

  ngOnInit(): void {
  }

  public getNewsRoute(): string {
    return `/${Consts.newsRoute}`;
  }
}
