import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { NewsItems, NewsService, PaginationBaseComponent } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { variable } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-category-news',
  templateUrl: './category-news.component.html',
  styleUrls: ['./category-news.component.scss']
})
export class CategoryNewsComponent extends PaginationBaseComponent implements OnInit, OnDestroy {
  public newsItems$: Observable<NewsItems>;
  private customSubscription: Subscription;

  constructor(private newsService: NewsService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router) {
    super(router, activatedRoute)
    this.customSubscription = this.activatedRoute.params.pipe(
      mergeMap(params => this.activatedRoute.queryParamMap.pipe(map(queryParam => [params, queryParam])))
    ).subscribe(([params, queryParam]) => {
      let categoryId = 0;
      if (params.categoryId) {
        categoryId = Number(params.categoryId as number);
      }

      let nextPage = 1;
      if (queryParam.has('page')) {
        nextPage = Number(queryParam.get('page'));
      }
      this.updateNewsItems(categoryId, nextPage);
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.customSubscription.unsubscribe();
  }

  protected updateNewsItems(categoryId: number, page: number) {
    this.page = page;
    this.newsItems$ = this.newsService.getCategoryNews(categoryId, page - 1, this.itemsPerPage).pipe(shareReplay(1));
    this.subscription?.unsubscribe();
    this.subscription = this.newsItems$.subscribe(newsItems => {
      this.totalNumberOfPages = Math.ceil(newsItems.total / this.itemsPerPage);
    });
  }
}
