import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { Consts } from '../../consts';
import { CreateEditTeam, EPlayersCategory, TeamsService } from 'shared';
import { catchError, first, switchMap } from 'rxjs/operators';
import { forkJoin, of, Observable } from 'rxjs';

@Component({
  selector: 'app-team-admin-create-edit-team',
  templateUrl: './team-admin-create-edit-team.component.html',
  styleUrls: ['./team-admin-create-edit-team.component.scss']
})
export class TeamAdminCreateEditTeamComponent implements OnInit {
  public teamForm: FormGroup = new FormGroup({
    name: new FormControl(undefined, [Validators.required]),
    description: new FormControl(undefined),
    location: new FormGroup({
      streetOne: new FormControl(undefined, [Validators.required]),
      streetTwo: new FormControl(undefined),
      postalCode: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{4}")]),
      city: new FormControl(undefined, [Validators.required])
    }),
    playersCategory: new FormControl(EPlayersCategory.Men, [Validators.required]),
  });
  public imageUrl = '';
  private readonly id?: number;
  private readonly creating: boolean;
  private file: File;

  constructor(private activatedRoute: ActivatedRoute,
              private teamsService: TeamsService,
              private modalService: ModalService,
              private toastService: ToastService,
              private router: Router) {
    const params = this.activatedRoute.snapshot.params;
    this.creating = true;
    if (params.teamId) {
      this.id = params.teamId as number;
      this.creating = false;
      this.teamsService.getTeam(this.id).pipe(first()).subscribe(team => {
        this.teamForm.patchValue(team);
        this.imageUrl = `${team.logo}?updated=${new Date().getTime()}`;
      }, error => {
        this.modalService.showModal('modal.error', 'global.teamNotFound')
          .subscribe(_ => this.router.navigate([Consts.userRoot]));
      });
    }
  }

  ngOnInit() {
  }

  public getPlayersCategories(): string[] {
    return Object.keys(EPlayersCategory);
  }

  public getButtonText(): string {
    if (this.creating) {
      return 'teamAdmin.team.create';
    } else {
      return 'teamAdmin.team.update';
    }
  }

  public getHeaderText(): string {
    if (this.creating) {
      return 'teamAdmin.team.create';
    } else {
      return 'teamAdmin.team.update';
    }
  }

  public updateTeam() {
    const data = this.teamForm.value as CreateEditTeam;
    if (this.creating) {
      this.teamsService.createValidatedTeam(data).pipe(
        catchError(_ => this.modalService.showModal('modal.error', 'teamAdmin.team.nameExists')),first(),
        switchMap(teamItem => {
          {
            if (this.file) {
              return this.teamsService.setTeamImage(teamItem.id, this.file).pipe(first());
            }

            return of({});
          }
        }))
        .subscribe(_ =>
            this.router.navigate([Consts.userRoot, Consts.userTeams])
        );
    } else {
      forkJoin([
        this.teamsService.updateTeam(this.id, data),
        this.teamsService.setTeamImage(this.id, this.file).pipe(first())
      ]).pipe(catchError(_ => this.modalService.showModal('modal.error', 'teamAdmin.team.updatedContentError')),
        first())
        .subscribe(_ => {
          this.toastService.showToast('teamAdmin.team.updatedTitle', 'teamAdmin.team.updatedContent');
          this.router.navigate([Consts.userRoot, Consts.userTeams]);
        });
    }
  }

  public onFileChanged(file: File) {
    this.file = file;
  }
}
