import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Sponsor } from '../../interfaces/sponsor';
import { SponsorGroup } from '../../interfaces/sponsor-group';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sponsor-sidebar',
  templateUrl: './sponsor-sidebar.component.html',
  styleUrls: ['./sponsor-sidebar.component.scss']
})
export class SponsorSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  public sponsorGroups: SponsorGroup[] = [
    {
      id: 'mainSponsor',
      title: 'sponsors.mainSponsor',
      sponsors: [
        {
          image: './assets/sponsors/your-logo.png',
          youLogoHere: true
        } as Sponsor,
      ]
    } as SponsorGroup,
    {
      id: 'rankingsSponsor',
      title: 'sponsors.rankingsSponsor',
      sponsors: [
        {
          image: './assets/sponsors/your-logo.png',
          youLogoHere: true
        } as Sponsor,
      ]
    } as SponsorGroup,
    {
      id: 'goldSponsor',
      title: 'sponsors.goldSponsor',
      sponsors: [
        {
          image: './assets/sponsors/gold-sponsor/djurs_sport_vertical_HVID.png',
          link: 'https://www.djurssport.dk'
        } as Sponsor
      ]
    } as SponsorGroup,
    {
      id: 'silverSponsor',
      title: 'sponsors.silverSponsor',
      sponsors: [
        {
          image: './assets/sponsors/your-logo.png',
          youLogoHere: true
        } as Sponsor,
      ]
    } as SponsorGroup,
    {
      id: 'bronzeSponsor',
      title: 'sponsors.bronzeSponsor',
      sponsors: [
        {
          image: './assets/sponsors/bronze-sponsor/komponenthuset.jpg',
          link: 'https://www.komponenthuset.dk'
        } as Sponsor,
        {
          image: './assets/sponsors/bronze-sponsor/fmsikring.png',
          link: 'https://www.fmsikring.dk'
        } as Sponsor
      ]
    } as SponsorGroup,
    {
      id: 'eventSponsor',
      title: 'sponsors.eventSponsor',
      sponsors: [
        {
          image: './assets/sponsors/event-sponsor/join_and_move.png',
          link: 'https://joinandmove.dk/'
        } as Sponsor,
        {
          image: './assets/sponsors/event-sponsor/djursfad.png',
          link: 'http://djursfad.dk/'
        } as Sponsor
      ]
    } as SponsorGroup,
    {
      id: 'clothesSponsor',
      title: 'sponsors.clothesSponsor',
      sponsors: [
        {
          image: './assets/sponsors/clothes-sponsor/djurs_sport_vertical_HVID.png',
          link: 'https://www.djurssport.dk'
        } as Sponsor,
        {
          image: './assets/sponsors/clothes-sponsor/joma-all-for-sport.png',
          link: 'https://www.joma-sport.com/en'
        } as Sponsor
      ]
    } as SponsorGroup,
    {
      id: 'bettingPartner',
      title: 'sponsors.bettingPartner',
      sponsors: [
        {
          image: './assets/sponsors/your-logo.png',
          youLogoHere: true
        } as Sponsor
      ]
    } as SponsorGroup,
  ];

  @ViewChildren('carousel') carousels: QueryList<NgbCarousel>;
  private interval: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.carousels.forEach(item => item.pause());
    this.interval = setInterval(() => {
      for (let sponsorGroup of this.sponsorGroups) {
        this.carousels.forEach(item => item.next());
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
