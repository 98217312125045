<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <div *ngIf="news$ | async as news">
        <div>
          <h1 class="header">{{news.title}}</h1>
        </div>
        <div>
          <span class="news-date">{{news.createdDate | date: 'longDate' : 'IST' : customTranslationService.getLang() | titlecase}}</span>
        </div>
        <hr class="news-divider">
        <div>
          <img class="image" src="{{news.image}}" alt="">
        </div>
        <div class="text">
          <quill-view-html [content]="news.content" ></quill-view-html>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>
