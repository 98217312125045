import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs';
import { Modal } from '../../interfaces/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('content') content: ElementRef;
  private modal$: Subscription;
  public openModal: Modal;

  private openModalRef: NgbModalRef;

  constructor(private modalService: ModalService, private ngbModal: NgbModal) {
  }

  ngOnInit(): void {
    this.modal$ = this.modalService.modals()
      .subscribe(modal => {
        this.openModal = modal;
        this.openModalRef = this.ngbModal.open(this.content, { centered: true, scrollable: true});
      });

  }

  ngOnDestroy(): void {
    this.modal$.unsubscribe();
  }

  public onButtonClicked(confirm: boolean) {
    this.openModal.buttonAction?.next(confirm);
    this.openModalRef.close();
  }
}
