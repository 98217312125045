import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { News } from '../interfaces/news';
import { NewsItems } from '../interfaces/news-items';
import { Observable } from 'rxjs';
import { CreateEditNews } from '../interfaces/create-edit-news';
import { apiUrlToken } from '../injection-tokens';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly urlPrefix = 'api/News';

  constructor(private httpClient: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getNews(page: number, count: number): Observable<NewsItems> {
    return this.httpClient.get<NewsItems>(`${this.apiUrl}/${this.urlPrefix}/GetNews?page=${page}&count=${count}`)
      .pipe(map(ni => this.setExcerpt(ni)));
  }

  public getNewsItem(id: number): Observable<News> {
    return this.httpClient.get<News>(`${this.apiUrl}/${this.urlPrefix}/GetNews/${id}`);
  }

  public getCategoryNews(categoryId: number, page: number, count: number): Observable<NewsItems> {
    return this.httpClient.get<NewsItems>(`${this.apiUrl}/${this.urlPrefix}/GetCategoryNews/${categoryId}?page=${page}&count=${count}`)
      .pipe(map(ni => this.setExcerpt(ni)));
  }

  public createNews(createEditNews: CreateEditNews): Observable<News> {
    return this.httpClient.post<News>(`${this.apiUrl}/${this.urlPrefix}/CreateNews`, createEditNews);
  }

  public updateNews(id: number, createEditNews: CreateEditNews): Observable<News> {
    return this.httpClient.put<News>(`${this.apiUrl}/${this.urlPrefix}/UpdateNews/${id}`, createEditNews);
  }

  public setNewsImage(id: number, file: File): Observable<News> {
    const form = new FormData();
    form.append('file', file)
    return this.httpClient.post<News>(`${this.apiUrl}/${this.urlPrefix}/SetNewsImage/${id}`, form);
  }

  public deleteNews(id: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/${this.urlPrefix}/DeleteNews/${id}`);
  }

  public readMoreString(): string {
    return ' […]';
  }

  private setExcerpt(newsItems: NewsItems): NewsItems {
    newsItems.newsItems.forEach(n => {
      const excerpt = n.content.substring(0, 120 - this.readMoreString().length)
      n.excerpt = excerpt.replace(/(<([^>]+)>)/gi, "");
    });
    return newsItems;
  }
}
