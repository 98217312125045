<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h1 class="header">{{'events.eventsList.events' | translate}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form (submit)="search()" [formGroup]="sportEventsSearchForm">
<!--        <div>-->
<!--          <h1 class="header">{{'events.eventsList.eventSearchHeader' | translate}}</h1>-->
<!--        </div>-->
        <div class="form-row">
          <div class="form-group col-md-6">
            <label class="form-label" for="name">{{'events.eventsList.name' | translate}}</label>
            <input id="name" class="form-control" formControlName="name" type="text"/>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="startDate">{{'events.eventsList.startDate' | translate}}</label>
            <input id="startDate" class="form-control" formControlName="startDate" type="date"/>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label class="form-label" for="city">{{'events.eventsList.city' | translate}}</label>
            <input id="city" class="form-control" formControlName="city" type="text"/>
          </div>
        </div>
        <div class="form-group">
          <label>{{'global.indoorFootballEventType' | translate}}</label>
          <div class="form-row me-0 ms-0">
            <div class="form-group">
              <div class="form-check form-check-inline" *ngFor="let indoorFootballEventType of getIndoorFootballEventTypes()">
                <input class="form-check-input" type="checkbox" value="" id="{{indoorFootballEventType}}" formControlName="{{indoorFootballEventType}}">
                <label class="form-check-label" for="{{indoorFootballEventType}}">{{'global.indoorFootballEventTypeItem.' + indoorFootballEventType | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>{{'global.playersCategory' | translate}}</label>
          <div class="form-row me-0 ms-0">
            <div class="form-group">
              <div class="form-check form-check-inline" *ngFor="let playerCategory of getPlayerCategories()">
                <input class="form-check-input" type="checkbox" value="" id="{{playerCategory}}" formControlName="{{playerCategory}}">
                <label class="form-check-label" for="{{playerCategory}}">{{'global.playersCategoryItem.' + playerCategory | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>{{'global.indoorSoccerEventTier' | translate}}</label>
          <div class="form-row me-0 ms-0">
            <div class="form-group">
              <div class="form-check form-check-inline" *ngFor="let indoorSoccerEventTier of getIndoorSoccerEventTiers() | slice:1">
                <input class="form-check-input" type="checkbox" value="" id="{{indoorSoccerEventTier}}" formControlName="{{indoorSoccerEventTier}}">
                <label class="form-check-label" for="{{indoorSoccerEventTier}}">{{'global.indoorSoccerEventTierItem.' + indoorSoccerEventTier | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <button class="form-button btn text-uppercase" type="button" name="searchButton" (click)="search()">
              {{'generalForm.search' | translate}}
            </button>
          </div>
          <div class="form-group col-md-3">
            <button class="form-button btn text-uppercase" type="button" name="resetButton" (click)="reset()">
              {{'generalForm.reset' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row" *ngFor="let sportEvent of (sportEvents$ | async)?.events">
    <div class="col-md-8 event-top-border pt-2 pb-2 position-relative">
      <img *ngIf="!isNormalEventTier(sportEvent)" src="assets/event/{{sportEvent.indoorSoccerEventTier | lowercase}}.png" class="medal">
      <table class="table custom-table">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{sportEvent.name}}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'global.indoorFootballEventType' | translate}}:</strong></td>
          <td>{{'global.indoorFootballEventTypeItem.' + sportEvent.indoorFootballEventType | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.playersCategory' | translate}}:</strong></td>
          <td>{{'global.playersCategoryItem.' + sportEvent.playersCategory | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.inviteOnly' | translate}}:</strong></td>
          <td>{{(sportEvent.inviteOnlyEvent ? 'global.yes' : 'global.no') | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.startDate' | translate}}:</strong></td>
          <td>{{sportEvent.startDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.endDate' | translate}}:</strong></td>
          <td>{{sportEvent.endDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr *ngIf="sportEvent.firstPrize">
          <td><strong>1. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.firstPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.secondPrize">
          <td><strong>2. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.secondPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.thirdPrize">
          <td><strong>3. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.thirdPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.fourthPrize">
          <td><strong>4. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.fourthPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.fifthPrize">
          <td><strong>5. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.fifthPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.sixthPrize">
          <td><strong>6 {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.sixthPrize}}</td>
        </tr>
        <tr>
          <td>
            <strong>{{'event.venue' | translate}}:</strong>
          </td>
          <td>{{sportEvent.venue}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.address' | translate}}:</strong></td>
          <td>{{sportEvent.location.streetOne}}, {{sportEvent.location.streetTwo}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.postalCodeCity' | translate}}:</strong></td>
          <td>{{sportEvent.location.postalCode}} {{sportEvent.location.city}}</td>
        </tr>
        </tbody>
      </table>
      <button class="action-button form-button btn text-uppercase" type="button" name="searchButton" [routerLink]="[getEventRoute(), sportEvent.id]">
        {{'generalForm.more' | translate}}
      </button>
    </div>
    <div class="col-md-4 event-top-border pt-2 pb-2 ">
      <div class="item-header">
        <h5>{{'event.registeredTeams' | translate}}</h5>
      </div>
      <div *ngFor="let team of sportEvent.registeredTeams">{{team.name}}</div>
    </div>
  </div>
</div>
<eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="gotToPage($event)" (nextPageEvent)="getNextPage($event)" (prevPageEvent)="prevPage($event)"></eventShop-pagination-component>
