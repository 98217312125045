import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SportEvent } from '../interfaces/sport-event';
import { apiUrlToken } from '../injection-tokens';
import { SportEvents } from '../interfaces/sport-events';
import { CreateEditSportEvent } from '../interfaces/creat-edit-sport-event';
import { SportEventsSearch } from '../interfaces/sport-events-search';
import { RegisterTeam } from '../interfaces/register-team';
import { Rankings } from '../interfaces/rankings';
import { EventShopUser } from '../interfaces/eventShopUser';

@Injectable({
  providedIn: 'root'
})
export class SportEventsService {
  private readonly urlPrefix = 'api/SportEvents';

  constructor(private httpClient: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getSportEvents(page: number, count: number, sportEventsSearch: SportEventsSearch): Observable<SportEvents> {
    return this.httpClient.post<SportEvents>(`${this.apiUrl}/${this.urlPrefix}/GetSportEvents?page=${page}&count=${count}`, sportEventsSearch);
  }

  public getSportEventsForUser(page: number, count: number): Observable<SportEvents> {
    return this.httpClient.get<SportEvents>(`${this.apiUrl}/${this.urlPrefix}/GetSportEventsForUser?page=${page}&count=${count}`);
  }

  public getSportEvent(eventId: number): Observable<SportEvent> {
    return this.httpClient.get<SportEvent>(`${this.apiUrl}/${this.urlPrefix}/GetSportEvent/${eventId}`);
  }

  public createSportEvent(createEditSportEvent: CreateEditSportEvent): Observable<SportEvent> {
    return this.httpClient.post<SportEvent>(`${this.apiUrl}/${this.urlPrefix}/CreateSportEvent`, createEditSportEvent);
  }

  public updateSportEvent(id: number, createEditSportEvent: CreateEditSportEvent): Observable<SportEvent> {
    return this.httpClient.put<SportEvent>(`${this.apiUrl}/${this.urlPrefix}/UpdateSportEvent/${id}`, createEditSportEvent);
  }

  public deleteSportEvent(id: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/api/Events/DeleteEvent/${id}`);
  }

  public registerTeam(eventId: number, registerTeam: RegisterTeam): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}/${this.urlPrefix}/RegisterTeam/${eventId}`, registerTeam);
  }

  public getRankings(): Observable<Rankings> {
    return this.httpClient.get<Rankings>(`${this.apiUrl}/${this.urlPrefix}/GetRankings`);
  }

  public getAdmins(eventId: number): Observable<EventShopUser[]> {
    return this.httpClient.get<EventShopUser[]>(`${this.apiUrl}/api/Events/GetAdmins/${eventId}`);
  }

  public addAdmin(eventId: number, userId: number): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Events/AddAdmins/${eventId}`, [userId]);
  }

  public removeAdmin(eventId: number, userId: number): Observable<any>  {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Events/RemoveAdmins/${eventId}`, [userId]);
  }
}
