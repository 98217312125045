import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SignedInGuard, EUserRole} from 'shared';

@Injectable({
  providedIn: 'root'
})
export class TeamsGuard extends SignedInGuard implements CanActivate {
  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isSingedIn = await super.canActivate(next, state);
    return isSingedIn && this.authService.hasRole(EUserRole.TeamAdmin);
  }
}
