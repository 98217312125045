import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrlToken } from '../injection-tokens';
import { Observable } from 'rxjs';
import { NewsCategory } from '../interfaces/news-category';

@Injectable({
  providedIn: 'root'
})
export class NewsCategoriesService {
  private readonly urlPrefix = 'api/NewsCategories';

  constructor(private httpClient: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getNewsCategories(): Observable<NewsCategory[]> {
    return this.httpClient.get<NewsCategory[]>(`${this.apiUrl}/${this.urlPrefix}/GetNewsCategories/`);
  }

  public getNewsCategoriesWithNewsCount(): Observable<NewsCategory[]> {
    return this.httpClient.get<NewsCategory[]>(`${this.apiUrl}/${this.urlPrefix}/GetNewsCategoriesWithNewsCount/`);
  }

  public getNewsCategory(id: number): Observable<NewsCategory> {
    return this.httpClient.get<NewsCategory>(`${this.apiUrl}/${this.urlPrefix}/GetNewsCategory/${id}`);
  }

  public createNewsCategory(newsCategory: NewsCategory): Observable<NewsCategory> {
    return this.httpClient.post<NewsCategory>(`${this.apiUrl}/${this.urlPrefix}/CreateNewsCategory`, newsCategory);
  }

  public updateNewsCategory(id: number, newsCategory: NewsCategory): Observable<NewsCategory> {
    return this.httpClient.put<NewsCategory>(`${this.apiUrl}/${this.urlPrefix}/UpdateNewsCategory/${id}`, newsCategory);
  }

  public deleteNewsCategory(id: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/${this.urlPrefix}/DeleteNewsCategory/${id}`);
  }
}
