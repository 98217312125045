import { Component, OnInit } from '@angular/core';
import { Team, TeamsService } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { catchError, first, take, takeLast } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  public team$: Observable<Team>;

  constructor(private teamService: TeamsService, private activatedRoute: ActivatedRoute, private modalService: ModalService, private router: Router) {
    const params = this.activatedRoute.snapshot.params;
    if (params.teamId) {
      this.team$ = this.teamService.getTeam(params.teamId as number).pipe(
        catchError(err => {
          this.modalService.showModal('modal.error', 'global.teamNotFound')
            .subscribe(_ => this.router.navigate(['/']))
          return of<Team>();
        }));
    }
  }

  ngOnInit(): void {
  }

}
