import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, EventShopUser } from 'shared';
import { Consts } from '../../consts';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public user$: Observable<EventShopUser>;

  constructor(private authService: AuthService) {
    this.user$ = authService.userChanged$;
  }

  ngOnInit(): void {
  }

  public getEditUserRoute(): string {
    return `/${Consts.userRoot}/${Consts.editUser}`;
  }

  public getChangePasswordRoute(): string {
    return `/${Consts.userRoot}/${Consts.userChangePasswordRoute}`;
  }
}
