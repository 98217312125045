import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Team } from '../interfaces/team';
import { CreateEditTeam } from '../interfaces/create-edit-team';
import { Teams } from '../interfaces/teams';
import { apiUrlToken } from '../injection-tokens';
import { SearchTeams } from '../interfaces/search-teams';
import { EventShopUser } from '../interfaces/eventShopUser';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  private readonly urlPrefix = 'api/teams';

  constructor(protected httpClient: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getTeams(page: number, count: number): Observable<Teams> {
    return this.httpClient.get<Teams>(`${this.apiUrl}/${this.urlPrefix}/GetTeams?page=${page}&count=${count}`);
  }

  public getTeam(id: number): Observable<Team> {
    return this.httpClient.get<Team>(`${this.apiUrl}/${this.urlPrefix}/GetTeam/${id}`);
  }

  public createTeam(createEditTeam: CreateEditTeam): Observable<Team> {
    return this.httpClient.post<Team>(`${this.apiUrl}/${this.urlPrefix}/CreateTeam`, createEditTeam);
  }

  public createValidatedTeam(createEditTeam: CreateEditTeam): Observable<Team> {
    return this.httpClient.post<Team>(`${this.apiUrl}/${this.urlPrefix}/CreateValidatedTeam`, createEditTeam);
  }

  public updateTeam(id: number, createEditTeam: CreateEditTeam): Observable<Team> {
    return this.httpClient.put<Team>(`${this.apiUrl}/${this.urlPrefix}/UpdateTeam/${id}`, createEditTeam);
  }

  public deactivateTeam(id: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/${this.urlPrefix}/DeactivateTeam/${id}`);
  }

  public getTeamsForUser(): Observable<Team[]> {
    return this.httpClient.get<Team[]>(`${this.apiUrl}/${this.urlPrefix}/GetTeamsForUser`);
  }

  public searchTeams(searchTeams: SearchTeams): Observable<Team[]> {
    return this.httpClient.post<Team[]>(`${this.apiUrl}/${this.urlPrefix}/SearchTeams`, searchTeams);
  }

  public getManagers(teamId: number): Observable<EventShopUser[]> {
    return this.httpClient.get<EventShopUser[]>(`${this.apiUrl}/${this.urlPrefix}/GetManagers/${teamId}`);
  }

  public addManager(teamId: number, userId: number): Observable<any> {
    return this.httpClient.post<EventShopUser[]>(`${this.apiUrl}/${this.urlPrefix}/AddManagers/${teamId}`, [userId]);
  }

  public removeManager(teamId: number, userId: number): Observable<any> {
    return this.httpClient.post<EventShopUser[]>(`${this.apiUrl}/${this.urlPrefix}/RemoveManagers/${teamId}`, [userId]);
  }

  public setTeamImage(teamId: number, file: File): Observable<Team> {
    const form = new FormData();
    form.append('file', file)
    return this.httpClient.post<Team>(`${this.apiUrl}/${this.urlPrefix}/SetTeamLogo/${teamId}`, form);
  }
}
