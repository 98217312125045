import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-sign-in',
  templateUrl: './user-sign-in.component.html',
  styleUrls: ['./user-sign-in.component.scss']
})
export class UserSignInComponent implements OnInit {
  public userForm: FormGroup = new FormGroup({
    username: new FormControl(undefined, [Validators.required]),
    password: new FormControl(undefined, [Validators.required]),
  });
  public showError = false;
  private returnUrl?: string;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(first()).subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  public signIn(): void {
    this.authService.signIn(this.userForm.value).pipe(first()).subscribe(_ => {
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(['/']);
      }
    }, _ =>{
      this.showError = true;
      this.userForm.value.password = null;
    } );
  }

}
