import { ETimeOfDay } from '../enums/etime-of.day';
import { EPaymentMethod } from '../enums/epayment-method.enum';
import { EPlayersCategory } from '../enums/eplayers-category.enum';

export class RegisterTeam {
  teamId?: number;
  teamName: string;
  registering: boolean;
  timeOfDay: ETimeOfDay[];
  paymentMethod: EPaymentMethod;
  playersCategory: EPlayersCategory;
  payed: boolean;
  comment: string;
  contactEmail: string;
  contactPhone: string;
  contactName: string;
}
