<div class="news-container">
  <div class="container-fluid ps-0 pe-0">
    <div class="row">
      <div class="col-md-4">
        <div class="news-image">
          <a [routerLink]="[getNewsRoute(), news.id]">
            <img src="{{news.image}}" alt="">
          </a>
        </div>
      </div>
      <div class="col-md-8">
        <div class="item-header">
          <h5>
            <a [routerLink]="[getNewsRoute(), news.id]">{{news.title}}</a>
          </h5>
          <p>{{news.excerpt}}{{newsService.readMoreString()}}</p>
          <div class="news-details">
            <span class="news-date">
                              {{news.createdDate | date: 'longDate' : 'IST' : customTranslationService.getLang() | titlecase}}
                            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
