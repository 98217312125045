<div class="ps-0 pe-0">
  <a target="_blank" class="sponsor-link" href="https://fc-glesborg.safeticket.dk/dm2025">
    <img class="d-block w-100 mb-3" src="/assets/sponsors/dm.png"/>
  </a>
  <div *ngFor="let sponsorGroup of sponsorGroups">
    <div class="sponsor-title">
      <h5>{{sponsorGroup.title | translate}}</h5>
    </div>
    <ngb-carousel #carousel [showNavigationArrows]="false" [showNavigationIndicators]="false">
      <ng-template ngbSlide *ngFor="let sponsor of sponsorGroup.sponsors">
        <img *ngIf="!sponsor.link" class="sponsor" src="{{sponsor.image}}"/>
        <a *ngIf="sponsor.link" target="_blank" class="sponsor-link" href="{{sponsor.link}}">
          <img class="sponsor" src="{{sponsor.image}}"/>
        </a>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
