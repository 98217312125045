<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div>
        <h1 class="header">{{'user.eventsList.table' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" *ngFor="let sportEvent of (sportEvents$ | async)?.events">
    <div class="col-md-4 pt-2 event-top-border mb-4" >
      <table class="table custom-table">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{sportEvent.name}}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'event.startDate' | translate}}:</strong></td>
          <td>{{sportEvent.startDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.endDate' | translate}}:</strong></td>
          <td>{{sportEvent.endDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.indoorSoccerEventTier' | translate}}:</strong></td>
          <td>{{'global.indoorSoccerEventTierItem.' + sportEvent.indoorSoccerEventTier | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.indoorFootballEventType' | translate}}:</strong></td>
          <td>{{'global.indoorFootballEventTypeItem.' + sportEvent.indoorFootballEventType | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.playersCategory' | translate}}:</strong></td>
          <td>{{'global.playersCategoryItem.' + sportEvent.playersCategory | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.inviteOnly' | translate}}:</strong></td>
          <td>{{(sportEvent.inviteOnlyEvent ? 'global.yes' : 'global.no') | translate}}</td>
        </tr>
        <tr>
          <td>
            <strong>{{'event.venue' | translate}}:</strong>
          </td>
          <td>{{sportEvent.venue}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.address' | translate}}:</strong></td>
          <td>{{sportEvent.location.streetOne}}, {{sportEvent.location.streetTwo}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.postalCodeCity' | translate}}:</strong></td>
          <td>{{sportEvent.location.postalCode}} {{sportEvent.location.city}}</td>
        </tr>
        </tbody>
      </table>
      <button class="action-button form-button btn text-uppercase" type="button" name="searchButton" [routerLink]="[getEventRoute(), sportEvent.id]">
        {{'generalForm.more' | translate}}
      </button>
    </div>
    <div class="col-md-4 event-top-border pt-2">
      <div ngbDropdown class="dropdown">
        <button ngbDropdownToggle class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'admin.news.options' | translate}}
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="addRemoveAdmins(sportEvent.id)">{{'global.admin' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="editEvent(sportEvent.id)">{{'global.edit' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="deleteEvent(sportEvent.id)">{{'global.delete' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="gotToPage($event)" (nextPageEvent)="getNextPage($event)" (prevPageEvent)="prevPage($event)"></eventShop-pagination-component>
