import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { Subscription } from 'rxjs';
import { Toast } from '../../interfaces/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  private toast$: Subscription;
  public toasts: Toast[] = [];

  constructor(private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.toast$ = this.toastService.toasts()
      .subscribe(toast => {
        this.toasts.push(toast);
      });
  }

  ngOnDestroy(): void {
    this.toast$.unsubscribe();
  }

  toastHidden(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
