<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="item-header">
        <h5>{{'user.userInfo' | translate}}</h5>
      </div>
      <div *ngIf="user$ | async as user">
        <table class="table custom-table">
          <tbody>
          <tr>
            <td><strong>{{'user.firstName' | translate}}:</strong></td>
            <td>{{user.firstName}}</td>
          </tr>
          <tr>
            <td><strong>{{'user.lastName' | translate}}:</strong></td>
            <td>{{user.lastName}}</td>
          </tr>
          <tr>
            <td><strong>{{'user.email' | translate}}:</strong></td>
            <td>{{user.email}}</td>
          </tr>
          <tr>
            <td><strong><strong>{{'user.phoneNumber' | translate}}:</strong>:</strong></td>
            <td>{{user.phoneNumber}}</td>
          </tr>
          </tbody>
        </table>
        <div>
          <button class="action-button form-button btn text-uppercase" type="button" name="searchButton" [routerLink]="[getEditUserRoute()]">
            {{'user.editUserInfo' | translate}}
          </button>
        </div>
        <div class="mt-3">
          <button class="action-button form-button btn text-uppercase" type="button" name="searchButton" [routerLink]="[getChangePasswordRoute()]">
            {{'user.changePassword' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
