import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService, ResetPassword } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordValidation, UtilService } from '../../services/util.service';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup = new FormGroup({
    newPassword: new FormControl(undefined, [Validators.required]),
    repeatedNewPassword: new FormControl(undefined, [Validators.required])
  });

  public passwordValidation: PasswordValidation;

  private code: string;
  private userId: string;

  constructor(private usersService: UsersService, private activatedRoute: ActivatedRoute, private router: Router, private utilService: UtilService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
      this.userId = params['userId'];
    });
  }

  public resetPassword() {
    if (this.resetPasswordForm.valid && this.resetPasswordForm.controls.newPassword.value == this.resetPasswordForm.controls.repeatedNewPassword.value && this.utilService.passwordValid(this.passwordValidation)) {
      const resetPassword = this.resetPasswordForm.value as ResetPassword;
      resetPassword.code = this.code;
      resetPassword.userId = this.userId;
      this.usersService.resetPassword(resetPassword).pipe(first())
        .subscribe(_ => this.router.navigate([Consts.userRoot]));
    } else {
      if (this.resetPasswordForm.controls.newPassword.value != this.resetPasswordForm.controls.repeatedNewPassword.value) {
        this.resetPasswordForm.controls.newPassword.setErrors({});
        this.resetPasswordForm.controls.repeatedNewPassword.setErrors({})
      }
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  public validatePassword() {
    this.passwordValidation = this.utilService.validatePassword(this.resetPasswordForm.controls.newPassword.value);
    if (this.passwordValidation.spacing && this.passwordValidation.special && this.passwordValidation.digit && this.passwordValidation.length && this.passwordValidation.smallLetter && this.passwordValidation.capitalLetter) {
      this.resetPasswordForm.controls.newPassword.setErrors(null);
    } else {
      this.resetPasswordForm.controls.newPassword.setErrors({});
    }
  }

  public validateRepeatedPassword() {
    if (this.resetPasswordForm.controls.newPassword.value != this.resetPasswordForm.controls.repeatedNewPassword.value) {
      this.resetPasswordForm.controls.repeatedNewPassword.setErrors({});
    } else {
      this.resetPasswordForm.controls.repeatedNewPassword.setErrors(null);
    }
  }
}
