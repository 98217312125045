<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="teamPointsForm" (ngSubmit)="updateTeamPoints()">
        <div class="form-group">
          <label  class="form-label" for="teamId">{{'admin.teams.choose' | translate}}</label>
          <select id="teamId" class="form-select" required formControlName="teamId" [ngClass]="teamPointsForm.controls.teamId.valid ? 'is-valid' : 'is-invalid'">
            <option *ngFor="let team of (teams$ | async)" value="{{team.id}}">{{team.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label  class="form-label" for="points">{{'admin.teams.points' | translate}}*</label>
          <input id="points" class="form-control content-input" formControlName="points" required [ngClass]="teamPointsForm.controls.teamId.valid ? 'is-valid' : 'is-invalid'"/>
          <small id="pointsHelpBlock" class="form-text text-muted">
            {{'admin.teams.pointsHelp' | translate}}
          </small>
        </div>
        <div class="form-group">
          <button class="form-button btn text-uppercase" type="submit" name="register" [disabled]="!teamPointsForm.valid">
            {{'admin.teams.addPoints' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
