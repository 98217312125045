import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslationService {
  private readonly dateFormat = 'dd-MM-yyyy'
  private readonly dateTimeFormat = 'dd-MM-yyyy - HH:MM:SS';

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('da-dk');
  }

  public setLang(lang: string) {
    this.translate.use(lang);
  }

  public getLang(): string {
    if (this.translate.currentLang === 'da-dk') {
      return 'da-GL';
    }

    return this.translate.currentLang;
  }

  public getDateFormat(): string {
    return this.dateFormat;
  }

  public getDateTimeFormat(): string {
    return this.dateTimeFormat;
  }
}
