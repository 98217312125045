import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CustomTranslationService } from 'shared';
import { AuthService } from 'shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private customTranslationService: CustomTranslationService, private titleService: Title, private authService: AuthService) {
    customTranslationService.setLang('da-dk');
    titleService.setTitle('Indebold.dk – alt om dansk indefodbold');
  }
}
