import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsLetterService } from 'shared';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  public signUpForm: FormGroup = new FormGroup({
    email: new FormControl(undefined, [Validators.required, Validators.email]),
  });
  public submitted: boolean;
  public error: string;

  constructor(private newsLetterService: NewsLetterService) {
  }

  ngOnInit(): void {
  }

  public signUp() {
    this.error = '';
    if (this.signUpForm.valid) {
      this.newsLetterService.subscribe(this.signUpForm.value.email).pipe(first()).subscribe(response => {
        this.submitted = true;
      }, error => {
        this.error = 'Sorry, an error occurred.';
      });
    }
  }
}

interface MailChimpResponse {
  result: string;
  msg: string;
}
