<div class="subscribe-container">
  <div>
    <div class="text-center w-100">
      <h2 class="strike-through-header"><span class="strike-through-header-span subscribe-header">{{'subscribe.title' | translate}}</span></h2>
      <p class="subscribe-subtitle text-center">{{'subscribe.subtitle' | translate}}</p>
    </div>
    <div class='subscribe-message' *ngIf="this.submitted">
      {{'subscribe.success' | translate}}
    </div>
    <div class='subscribe-message' *ngIf="this.error">
      {{'subscribe.error' | translate}} - {{this.error}}
    </div>
    <div class="form" *ngIf="!this.error && !this.submitted">
      <form (submit)="signUp()" [formGroup]="signUpForm">
        <div class="form-group">
          <input id="email" class="form-control test" formControlName="email" type="email" placeholder="{{'subscribe.email' | translate}}"/>
        </div>
        <div class="form-group mt-2">
          <button class="btn form-button text-uppercase" [disabled]="!signUpForm.valid" type="submit" name="submitButton">
            {{'subscribe.signUp' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
