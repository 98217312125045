<div class="top-header-margin pt-5 pb-5">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">{{'admin.newsCategories.name' | translate}}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let newsCategory of (newsCategories$ | async)">
            <td>{{newsCategory.categoryName}}</td>
            <td>
              <div ngbDropdown class="dropdown">
                <button ngbDropdownToggle class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{'admin.news.options' | translate}}
                </button>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="editNewsCategory(newsCategory.id)">{{'admin.newsCategories.edit' | translate}}</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="deleteNewsCategory(newsCategory.id)">{{'admin.newsCategories.delete' | translate}}</a>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
