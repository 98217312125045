<div class="top-header-margin pt-5 pb-5">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">{{'admin.news.title' | translate}}</th>
            <th scope="col">{{'admin.news.modified' | translate}}</th>
            <th scope="col">{{'admin.news.created' | translate}}</th>
            <th scope="col">{{'admin.news.by' | translate}}</th>
            <th scope="col">{{'admin.news.categories' | translate}}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let news of (newsItems$ | async)?.newsItems">
            <td>{{news.title}}</td>
            <td>{{news.modifiedDate | date: customTranslationService.getDateTimeFormat() : 'IST' : customTranslationService.getLang()}}</td>
            <td>{{news.createdDate | date: customTranslationService.getDateTimeFormat() : 'IST' : customTranslationService.getLang()}}</td>
            <td>{{news.createdBy.firstName}} {{news.createdBy.lastName}}</td>
            <td><span *ngFor="let newsCategory of news.categories">{{newsCategory.categoryName}}<br></span></td>
            <td>
              <div ngbDropdown class="dropdown">
                <button ngbDropdownToggle class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{'admin.news.options' | translate}}
                </button>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="editNews(news.id)">{{'admin.news.edit' | translate}}</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="deleteNews(news.id)">{{'admin.news.delete' | translate}}</a>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="gotToPage($event)" (nextPageEvent)="getNextPage($event)" (prevPageEvent)="prevPage($event)"></eventShop-pagination-component>
