import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrlToken } from '../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class NewsLetterService {
  private readonly urlPrefix = 'api/newsletter';

  constructor(protected httpClient: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public subscribe(email: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/${this.urlPrefix}/Subscribe`, {'email': email});
  }
}
