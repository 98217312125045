import { Component, Input, OnInit } from '@angular/core';
import { Team } from 'shared';
import { Consts } from '../../consts';

@Component({
  selector: 'app-team-list-item',
  templateUrl: './team-list-item.component.html',
  styleUrls: ['./team-list-item.component.scss']
})
export class TeamListItemComponent implements OnInit {
  @Input() team: Team

  constructor() {
  }

  ngOnInit(): void {
  }

  public getTeamRoute(): string {
    return `/${Consts.team}`
  }
}
