import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersService } from 'shared';
import { TeamAdmin } from '../../interfaces/team-admin';
import { Router } from '@angular/router';
import { Consts } from '../../consts';

@Component({
  selector: 'app-team-admin-team',
  templateUrl: './team-admin-team.component.html',
  styleUrls: ['./team-admin-team.component.scss']
})
export class TeamAdminTeamComponent implements OnInit {
  public teamAdminUser$: Observable<TeamAdmin>;

  constructor(private usersService: UsersService, private router: Router) {
  }

  ngOnInit(): void {
    // this.teamAdminUser$ = this.usersService.getUserInfo<TeamAdmin>();
  }

  public async createTeam() {
    await this.router.navigate([Consts.teamAdminTeamsRoute, Consts.teamAdminCreateEditTeamRoute]);
  }

  public async updateTeam(teamId: number) {
    await this.router.navigate([Consts.teamAdminTeamsRoute, Consts.teamAdminCreateEditTeamRoute, teamId]);
  }
}
