<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h1 class="header">{{'contact.contact' | translate}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center text-uppercase font-italic fw-bold team-header">{{'contact.title1' | translate}} <span class="green-text">{{'contact.title2' | translate}}</span></div>
      <div class="text-center text-uppercase subtitle fw-bold mt-3 mb-3">{{'contact.subTitle' | translate}}</div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center">
            <div>
              <div>
                <img class="contact-image" src="assets/contact/daniel.jpg">
              </div>
              <div class="text-center contact-box">
                <div class="name">Daniel Bloch</div>
                <div class="contact-info"><a href="mailto:db@indebold.dk">db@indebold.dk</a></div>
                <div class="contact-info">Tlf: <a href="tel:61770328">61 77 03 28</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="float-end">indebold.dk logo</div>-->
    </div>
  </div>
</div>
