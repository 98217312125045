<div class="col-md-12">
  <div class="row">
    <div class="col-md-12">
      <div>
        <h1 class="header">{{'event.user-admin.title' | translate }}</h1>
      </div>
    </div>
  </div>
  <div class="container-fluid ps-0 pe-0">
    <div class="row mt-5 justify-content-center">
      <div class="col-md-9 d-flex justify-content-center">
        <div>{{'event.user-admin.addUser' | translate}}</div>
      </div>
      <div class="col-md-9 d-flex justify-content-center">
        <form class="mx-auto">
          <div class="input-group mb-3" style="width: 350px;">
            <input type="text" class="form-control search-input" name="search" placeholder="{{'event.user-admin.searchEvent' | translate}}" [(ngModel)]="userSearch">
              <button class="btn btn-outline-secondary" type="button" id="search" (click)="searchUsers()">{{'event.user-admin.search' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
      <div class="mt-4 mb-4">
        <table class="table custom-table">
          <thead>
          <tr>
            <td>
              <strong>{{'event.user-admin.username' | translate}}</strong>
            </td>
            <td>
              <strong>{{'event.user-admin.name' | translate}}</strong>
            </td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let adminUser of (adminUsers$ | async)">
            <td>{{adminUser.username}}</td>
            <td>{{adminUser.firstName}} {{adminUser.lastName}}</td>
            <td>
              <button *ngIf="user.id !== adminUser.id" class="content-button-small" type="button" (click)="removeUser(adminUser.id)">
                <span>{{'global.remove' | translate}}</span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</div>
<ng-template #modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'event.user-admin.choose-user' | translate}}</h5>
  </div>
  <div class="modal-body">
    <table class="mt-5 mb-5">
      <tbody>
      <tr *ngFor="let user of foundUsers">
        <td class="pe-2">{{user.firstName}} {{user.lastName}}</td>
        <td>
          <div class="d-flex justify-content-center">
            <button class="content-button form-button" type="button" name="search" (click)="addUser(user.userId)">
              <span>{{'global.choose' | translate}}</span>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="cancelButtonPressed()">{{'modal.cancel' | translate}}</button>
  </div>
</ng-template>
