<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <app-team-list-item *ngFor="let team of (teams$ | async)?.teams" [team]="team"></app-team-list-item>
    </div>
  </div>
    <div class="row justify-content-center">
      <eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="gotToPage($event)" (nextPageEvent)="getNextPage($event)" (prevPageEvent)="prevPage($event)"></eventShop-pagination-component>
    </div>
</div>
