import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eventShop-pagination-base',
  templateUrl: './pagination-base.component.html',
  styleUrls: ['./pagination-base.component.css']
})
export class PaginationBaseComponent implements OnInit, OnDestroy {
  public page = 1;
  public totalNumberOfPages = 0;
  protected readonly itemsPerPage = 10;
  protected subscription = new Subscription();
  protected activatedRouteSubscription = new Subscription();

  constructor(protected router: Router, protected  activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.setupActivatedRouteSubscription();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.activatedRouteSubscription.unsubscribe();
  }

  protected setupActivatedRouteSubscription(){
    this.activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
      let nextPage = 1;
      if (params.has('page')) {
        nextPage = Number(params.get('page'));
      }
      this.updateItems(nextPage);
    });
  }

  protected updateItems(nextPage) {
  }

  public async getNextPage(page: number) {
    await this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {page}, queryParamsHandling: 'merge'});
  }

  public async prevPage(page: number) {
    await this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {page}, queryParamsHandling: 'merge'});
  }

  public async gotToPage(page: number) {
    await this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {page}, queryParamsHandling: 'merge'});
  }
}
