<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h1 class="header">{{'livescore.livescore' | translate}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col" class="small-width">#</th>
          <th scope="col" class="xl-width">{{'livescore.name' | translate}}</th>
          <th scope="col" class="small-width">{{'livescore.startDate' | translate}}</th>
          <th scope="col" class="small-width">{{'livescore.endDate' | translate}}</th>
          <th scope="col" class="large-width">{{'livescore.results' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr colspan="2" *ngFor="let livescoreItem of (livescoreItems$ | async), let i = index">
          <th scope="row" class="small-width number">
            {{i}}
          </th>
          <th scope="row" class="xl-width">
            {{livescoreItem.name}}
          </th>
          <td class="small-width">
            {{livescoreItem.startdate}}
          </td>
          <td class="small-width">
            {{livescoreItem.enddate}}
          </td>
          <td class="medium-width">
            <a *ngIf="livescoreItem.released" href="{{livescoreItem.livescore}}" target="_blank">{{'livescore.openLivescore' | translate}}</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
