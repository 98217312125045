<div class="col-md-12">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div>
          <h1 class="header">{{'teamAdmin.addManagers.title' | translate }}</h1>
        </div>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-9 d-flex justify-content-center">
        <div>{{'teamAdmin.addManagers.addUser' | translate}}</div>
      </div>
      <div class="col-md-9 d-flex justify-content-center">
        <form class="mx-auto">
          <div class="input-group mb-3" style="width: 350px;">
            <input type="text" class="form-control search-input" name="search" placeholder="{{'teamAdmin.addManagers.searchEvent' | translate}}" [(ngModel)]="userSearch">
            <button class="btn btn-outline-secondary" type="button" id="search" (click)="searchUsers()">{{'teamAdmin.addManagers.search' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mt-4 mb-4">
          <table class="table custom-table">
            <thead>
            <tr>
              <td>
                <strong>{{'teamAdmin.addManagers.username' | translate}}</strong>
              </td>
              <td>
                <strong>{{'teamAdmin.addManagers.name' | translate}}</strong>
              </td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let adminUser of (managers | async)">
              <td>{{adminUser.username}}</td>
              <td>{{adminUser.firstName}} {{adminUser.lastName}}</td>
              <td>
                <button *ngIf="user.id !== adminUser.id" class="content-button-small" type="button" (click)="removeManager(adminUser.id)">
                  <span>{{'global.remove' | translate}}</span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'teamAdmin.addManagers.choose-user' | translate}}</h5>
  </div>
  <div class="modal-body">
    <table class="mt-5 mb-5">
      <tbody>
      <tr *ngFor="let user of foundUsers">
        <td class="pe-2">{{user.firstName}} {{user.lastName}}</td>
        <td>
          <div class="d-flex justify-content-center">
            <button class="content-button form-button" type="button" name="search" (click)="addManager(user.userId)">
              <span>{{'global.choose' | translate}}</span>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="cancelButtonPressed()">{{'modal.cancel' | translate}}</button>
  </div>
</ng-template>
