<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'userChangePassword.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
        <div class="form-group">
          <label class="form-label" for="currentPassword">{{'userChangePassword.current' | translate}}*</label>
          <input id="currentPassword" class="form-control content-input" formControlName="currentPassword" required type="password"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="newPassword">{{'signUpUser.password' | translate}}*</label>
          <input id="newPassword" class="form-control content-input" formControlName="newPassword" required type="password" (ngModelChange)="validatePassword()" [ngClass]="changePasswordForm.controls.newPassword.valid ? 'is-valid' : 'is-invalid'"/>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.length">
            {{'signUpUser.length' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.capitalLetter">
            {{'signUpUser.capitalLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.smallLetter">
            {{'signUpUser.smallLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.digit">
            {{'signUpUser.digit' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.special">
            {{'signUpUser.special' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.spacing">
            {{'signUpUser.spacing' | translate}}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="repeatPassword">{{'signUpUser.repeatPassword' | translate}}*</label>
          <input id="repeatPassword" class="form-control content-input" formControlName="repeatPassword" required type="password" (ngModelChange)="validateRepeatedPassword()" [ngClass]="changePasswordForm.controls.repeatPassword.valid ? 'is-valid' : 'is-invalid'"/>
          <div class="password-invalid" *ngIf="changePasswordForm.controls.repeatPassword.invalid && (changePasswordForm.controls.repeatPassword.dirty || changePasswordForm.controls.repeatPassword.touched)">
            {{'signUpUser.notMatching' | translate}}
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="action-button form-button btn text-uppercase" type="submit" name="update">
            {{'userChangePassword.change' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
