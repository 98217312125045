import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventShopUser } from '../interfaces/eventShopUser';
import { apiUrlToken } from '../injection-tokens';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EUserRole } from '../enums/euser-role';
import { TokenResponse } from '../interfaces/token-response';
import { RefreshToken } from '../interfaces/refresh-token';
import { SignIn } from '../interfaces/sign-in';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly redirectRouteKey = 'redirectRouteKey';
  private readonly responseTokenKey = 'responseTokenKey';
  private currentUser: EventShopUser = null;
  public signedInChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userChanged$ = new ReplaySubject<EventShopUser>(1);

  constructor(private httpClient: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public isAuthenticated(): boolean {
    return this.getResponseTokenFromStorage() != null;
  }

  public getAccessToken(): string {
    return this.getResponseTokenFromStorage()?.accessToken ?? null;
  }

  public signIn(signIn: SignIn): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Users/SignIn`, signIn).pipe(
      tap({
        next: (tokenResponse: TokenResponse) => {
          this.setResponseTokenFromStorage(tokenResponse);
          this.getEventShopUser();
        }
      }));
  }

  public signOut(state?: INavigationState) {
    this.httpClient.get<any>(`${this.apiUrl}/api/Users/SignOut`)
      .subscribe(_ => {
        this.clearResponseTokenFromStorage();
        this.currentUser = null;
        this.signedInChanged$.next(false);
      });
  }

  public createArguments(state?: any): any {
    return {useReplaceToNavigate: true, data: state};
  }

  private getResponseTokenFromStorage(): TokenResponse | null {
    let json = sessionStorage.getItem(this.responseTokenKey);
    if (!json) {
      return null;
    }
    return JSON.parse(json);
  }

  private clearResponseTokenFromStorage(): void {
    sessionStorage.removeItem(this.responseTokenKey);
  }

  private setResponseTokenFromStorage(tokenResponse: TokenResponse): void {
    sessionStorage.setItem(this.responseTokenKey, JSON.stringify(tokenResponse));
  }

  public fetchUserInfo(): void {
    this.getUserObservable().pipe(first()).subscribe();
  }

  private getEventShopUser(): void {
    this.getUserObservable().pipe(first()).subscribe();
  }

  private getUserObservable(): Observable<EventShopUser> {
    return this.getCurrentUser().pipe(shareReplay(1), switchMap(u => of(u)), map(u => {
        this.currentUser = u;
        this.userChanged$.next(u);
        const isSignedIn = this.isSignedIn();
        this.signedInChanged$.next(isSignedIn);
        return u;
      }
    ));
  }

  private isSignedIn(): boolean {
    return this.currentUser != null;
  }

  public hasRole(userRole: EUserRole): boolean {
    return this.currentUser?.roles?.indexOf(userRole) > -1;
  }

  public getRedirectRoute(): string {
    return sessionStorage.getItem(this.redirectRouteKey);
  }

  private getCurrentUser(): Observable<EventShopUser> {
    return this.httpClient.get<EventShopUser>(`${this.apiUrl}/api/Users/CurrentUser`,
      {
        headers:
          {Authorization: this.authHeader()}
      });
  }

  public authHeader(): string {
    return `Bearer ${this.getAccessToken()}`;
  }

  public refreshAccessToken(refreshToken: RefreshToken): Observable<TokenResponse> {
    return this.httpClient.post<any>(`${this.apiUrl}/api/Users/Refresh`, RefreshToken);
  }
}

interface INavigationState {
  returnUrl: string;
}
