import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = new Subject<boolean>();

  private readonly loadingTimeout = 250;
  private loadingCounter = 0;

  constructor() {
  }

  public show() {
    this.isLoading.next(true);
    this.loadingCounter += 1;
  }

  public hide() {
    this.checkLoadingCounter();
  }

  private checkLoadingCounter() {
    this.loadingCounter -= 1;
    setTimeout(() => {
      if (this.loadingCounter <= 0) {
        this.loadingCounter = 0;
        this.isLoading.next(false);
      }
    }, this.loadingTimeout);
  }
}
