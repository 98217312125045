import { Component, OnInit } from '@angular/core';
import { Consts } from '../../consts';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import {
  CreateEditSportEvent,
  EEventSignUpType,
  EIndoorFootballEventType,
  EPaymentMethod,
  EPlayersCategory,
  SportEvent,
  SportEventsService,
  EIndoorSoccerEventTier
} from 'shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-edit-event',
  templateUrl: './create-edit-event.component.html',
  styleUrls: ['./create-edit-event.component.scss']
})
export class CreateEditEventComponent implements OnInit {
  public eventForm: FormGroup = new FormGroup({
    name: new FormControl(undefined, [Validators.required]),
    startDate: new FormControl(undefined, [Validators.required]),
    endDate: new FormControl(undefined, [Validators.required]),
    inviteOnlyEvent: new FormControl(false, [Validators.required]),
    isActive: new FormControl(undefined, []),
    indoorFootballEventType: new FormControl(EIndoorFootballEventType.BigGoals, [Validators.required]),
    maxPlayers: new FormControl(undefined, [Validators.required]),
    minPlayers: new FormControl(undefined, [Validators.required]),
    playersCategory: new FormControl(EPlayersCategory.Men, [Validators.required]),
    venue: new FormControl(undefined, [Validators.required]),
    location: new FormGroup({
      streetOne: new FormControl(undefined, [Validators.required]),
      streetTwo: new FormControl(undefined),
      postalCode: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{4}")]),
      city: new FormControl(undefined, [Validators.required])
    }),
    firstPrize: new FormControl(undefined),
    secondPrize: new FormControl(undefined),
    thirdPrize: new FormControl(undefined),
    fourthPrize: new FormControl(undefined),
    fifthPrize: new FormControl(undefined),
    sixthPrize: new FormControl(undefined),
    bankAccount: new FormGroup({
      registrationNumber: new FormControl(undefined, [Validators.pattern("[0-9]{4}")]),
      accountNumber: new FormControl(undefined, [Validators.pattern("[0-9]{1,10}")])
    }),
    signUpDeadline: new FormControl(undefined, [Validators.required]),
    entryFee: new FormControl(null),
    maxNumberOfTeams: new FormControl(null),
    organizer: new FormControl(undefined, [Validators.required]),
    contactPerson: new FormControl(undefined, [Validators.required]),
    contactEmail: new FormControl(undefined, [Validators.required, Validators.email]),
    contactPhone: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{8}")]),
    contactWebsite: new FormControl(undefined),
    comment: new FormControl(undefined, [Validators.maxLength(300)]),
    indoorSoccerEventTier: new FormControl(EIndoorSoccerEventTier.Normal, [Validators.required])
  });
  private readonly creating: boolean;
  private readonly id: number;
  private readonly totalPrizes = 5;

  constructor(private activatedRoute: ActivatedRoute, private modalService: ModalService, private toastService: ToastService, private router: Router, private sportEventsService: SportEventsService) {
    this.setupFormValues();

    const params = this.activatedRoute.snapshot.params;
    this.creating = true;
    if (params.eventId) {
      this.id = params.eventId as number;
      this.creating = false;
      this.sportEventsService.getSportEvent(params.eventId as number).pipe(first()).subscribe(event => {
        this.eventForm.patchValue(event);
        this.setFormValuesFromObject(event)
      }, error => {
        this.modalService.showModal('modal.error', 'global.eventNotFound')
          .subscribe(_ => this.router.navigate([Consts.userRoot, Consts.userEvents]));
      });
    }
  }

  ngOnInit(): void {
  }

  public getIndoorFootballEventTypes(): string[] {
    return Object.keys(EIndoorFootballEventType);
  }

  public getIndoorSoccerEventTier(): string[] {
    return Object.keys(EIndoorSoccerEventTier);
  }

  public getPaymentMethods(): string[] {
    return Object.keys(EPaymentMethod);
  }

  public getEventSignUpTypes(): string[] {
    return Object.keys(EEventSignUpType);
  }

  public getPlayersCategories(): string[] {
    return Object.keys(EPlayersCategory);
  }

  private setupFormValues() {
    for (const eventSignUpType of this.getEventSignUpTypes()) {
      this.eventForm.addControl(eventSignUpType, new FormControl(false));
    }

    for (const paymentMethod of this.getPaymentMethods()) {
      this.eventForm.addControl(paymentMethod, new FormControl(false));
    }
  }

  private setFormValuesFromObject(event: SportEvent) {
    for (let eventSignUpType of event.eventSignUpTypes) {
      this.eventForm.controls[eventSignUpType].setValue(true);
    }

    for (let paymentMethod of event.paymentMethods) {
      this.eventForm.controls[paymentMethod].setValue(true);
    }

    this.eventForm.controls.startDate.setValue(moment(new Date(event.endDate)).format("YYYY-MM-DD"));
    this.eventForm.controls.endDate.setValue(moment(new Date(event.endDate)).format("YYYY-MM-DD"));
    this.eventForm.controls.signUpDeadline.setValue(moment(new Date(event.signUpDeadline)).format("YYYY-MM-DD"));
  }

  public getButtonText(): string {
    if (this.creating) {
      return 'events.form.create';
    } else {
      return 'events.form.update';
    }
  }

  public getHeaderText(): string {
    if (this.creating) {
      return 'events.form.create';
    } else {
      return 'events.form.update';
    }
  }

  public customFieldsValid(): boolean {
    return this.eventSignUpTypesValid() && this.paymentMethodsValid();
  }

  public eventSignUpTypesValid(): boolean {
    let signUpSelected = false;
    for (const eventSignUpType of this.getEventSignUpTypes()) {
      signUpSelected = this.eventForm.controls[eventSignUpType].value;
      if (signUpSelected) {
        break;
      }
    }

    return signUpSelected;
  }

  public paymentMethodsValid(): boolean {
    let paymentMethodSelected = false;
    for (const paymentMethod of this.getPaymentMethods()) {
      paymentMethodSelected = this.eventForm.controls[paymentMethod].value;
      if (paymentMethodSelected) {
        break;
      }
    }

    return paymentMethodSelected;
  }

  public updateEvent() {
    const sportEvent = this.eventForm.value as CreateEditSportEvent;
    sportEvent.isActive = true;

    sportEvent.eventSignUpTypes = [];
    for (const eventSignUpType of this.getEventSignUpTypes()) {
      if (this.eventForm.controls[eventSignUpType].value) {
        sportEvent.eventSignUpTypes.push(eventSignUpType as EEventSignUpType);
      }
    }

    sportEvent.paymentMethods = [];
    for (const paymentMethod of this.getPaymentMethods()) {
      if (this.eventForm.controls[paymentMethod].value) {
        sportEvent.paymentMethods.push(paymentMethod as EPaymentMethod);
      }
    }

    if (this.creating) {
      this.sportEventsService.createSportEvent(sportEvent).pipe(first())
        .subscribe(_ =>
            this.router.navigate([Consts.userRoot, Consts.userEvents])
          , error => this.modalService.showModal('modal.error', 'events.form.createError'));
    } else {
      // this.toast
      this.sportEventsService.updateSportEvent(this.id, sportEvent).pipe(first())
        .subscribe(_ => {
          this.toastService.showToast('events.form.updatedTitle', 'events.form.updatedContent');
          this.router.navigate([Consts.userRoot, Consts.userEvents]);
        }, error => {
          this.modalService.showModal('modal.error', 'events.form.updatedContentError');
        });
    }
  }

  public today(): Date {
    return new Date();
  }
}
