<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div>
        <h1 class="header">{{'event.eventInfo' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="sportEvent$ | async as sportEvent">
    <div class="col-md-6 position-relative">
      <img *ngIf="!isNormalEventTier(sportEvent)" src="assets/event/{{sportEvent.indoorSoccerEventTier | lowercase}}.png" class="medal">
      <table class="table custom-table mt-2">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{sportEvent.name}}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'global.indoorFootballEventType' | translate}}:</strong></td>
          <td>{{'global.indoorFootballEventTypeItem.' + sportEvent.indoorFootballEventType | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.playersCategory' | translate}}:</strong></td>
          <td>{{'global.playersCategoryItem.' + sportEvent.playersCategory | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.inviteOnly' | translate}}:</strong></td>
          <td>{{(sportEvent.inviteOnlyEvent ? 'global.yes' : 'global.no') | translate}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.startDate' | translate}}:</strong></td>
          <td>{{sportEvent.startDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.endDate' | translate}}:</strong></td>
          <td>{{sportEvent.endDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr *ngIf="sportEvent.firstPrize">
          <td><strong>1. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.firstPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.secondPrize">
          <td><strong>2. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.secondPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.thirdPrize">
          <td><strong>3. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.thirdPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.fourthPrize">
          <td><strong>4. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.fourthPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.fifthPrize">
          <td><strong>5. {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.fifthPrize}}</td>
        </tr>
        <tr *ngIf="sportEvent.sixthPrize">
          <td><strong>6 {{'event.prize' | translate}}:</strong></td>
          <td>{{sportEvent.sixthPrize}}</td>
        </tr>
        <tr>
          <td>
            <strong>{{'event.venue' | translate}}:</strong>
          </td>
          <td>{{sportEvent.venue}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.address' | translate}}:</strong></td>
          <td>{{sportEvent.location.streetOne}}, {{sportEvent.location.streetTwo}}</td>
        </tr>
        <tr>
          <td><strong>{{'global.postalCodeCity' | translate}}:</strong></td>
          <td>{{sportEvent.location.postalCode}} {{sportEvent.location.city}}</td>
        </tr>
        </tbody>
      </table>
      <table class="table custom-table mt-2" *ngIf="!sportEvent.inviteOnlyEvent">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{'event.signUp' | translate}}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'event.signUp' | translate}}:</strong></td>
          <td>
            <div *ngFor="let eventSignUpType of sportEvent.eventSignUpTypes">{{'global.eventSignUpTypeItem.' + eventSignUpType | translate}}</div>
          </td>
        </tr>
        <tr *ngIf="sportEvent.entryFee && sportEvent.entryFee > 0">
          <td><strong>{{'event.entryFee' | translate}}:</strong></td>
          <td>Kr. {{sportEvent.entryFee}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.payment' | translate}}:</strong></td>
          <td>
            <div *ngFor="let paymentMethod of sportEvent.paymentMethods">{{'global.paymentMethodItem.' + paymentMethod | translate}}</div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'event.RegAndAccount' | translate}}:</strong></td>
          <td>{{sportEvent.bankAccount.registrationNumber}} {{sportEvent.bankAccount.accountNumber}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.signUpDeadline' | translate}}:</strong></td>
          <td>{{sportEvent.signUpDeadline | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang()}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.maxNumberOfTeams' | translate}}:</strong></td>
          <td *ngIf="sportEvent.maxNumberOfTeams && sportEvent.maxNumberOfTeams > 0">{{sportEvent.maxNumberOfTeams}}</td>
          <td *ngIf="!sportEvent.maxNumberOfTeams || sportEvent.maxNumberOfTeams == 0">{{'event.maxNumberOfTeamsNoLimit' | translate}}</td>
        </tr>
        </tbody>
      </table>
      <table class="table custom-table mt-2">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{'event.contact' | translate}}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'event.organizer' | translate}}:</strong></td>
          <td>{{sportEvent.organizer}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.contactName' | translate}}:</strong></td>
          <td>{{sportEvent.contactPerson}}</td>
        </tr>
        <tr>
          <td><strong>{{'event.contactEmail' | translate}}:</strong></td>
          <td><a href="mailto:{{sportEvent.contactEmail}}">{{sportEvent.contactEmail}}</a></td>
        </tr>
        <tr>
          <td><strong>{{'event.contactPhone' | translate}}:</strong></td>
          <td><a href="tel:{{sportEvent.contactPhone}}">{{sportEvent.contactPhone}}</a></td>
        </tr>
        <tr *ngIf="sportEvent.contactWebsite">
          <td><strong>{{'event.contactWebsite' | translate}}:</strong></td>
          <td><a href="{{sportEvent.contactWebsite}}" target="_blank">{{sportEvent.contactWebsite}}</a></td>
        </tr>
        </tbody>
      </table>
      <table class="table custom-table mt-2">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{'event.other' | translate}}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{'event.comment' | translate}}:</strong></td>
          <td style=" word-break: break-all">{{sportEvent.comment}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <div>
        <div class="item-header">
          <h5>{{'event.registeredTeams' | translate}}</h5>
        </div>
        <div *ngFor="let team of sportEvent.registeredTeams">
          {{team.deactivated ? ('global.deactivatedTeam' | translate) : team.name}}
        </div>
      </div>
      <div class="mt-5" *ngIf="!sportEvent.inviteOnlyEvent">
        <div class="item-header">
          <h5>{{'event.registerTeam' | translate}}</h5>
        </div>
        <div *ngIf="utilService.dateBeforeNow(sportEvent.startDate)">
          {{'event.started' | translate}}
        </div>
        <div *ngIf="utilService.dateBeforeNow(sportEvent.startDate) && sportEvent.maxNumberOfTeams > 0 && sportEvent.registeredTeams.length >= sportEvent.maxNumberOfTeams">
            {{'event.eventFull' | translate}}
        </div>
        <div *ngIf="utilService.dateAfterNow(sportEvent.startDate) && (sportEvent.maxNumberOfTeams == null || sportEvent.maxNumberOfTeams === 0 || sportEvent.registeredTeams.length < sportEvent.maxNumberOfTeams)">
          <div *ngIf="!(authService.signedInChanged$ | async)">
            <div>
              {{'event.registerTeamNotSignedIn' | translate}}
            </div>
            <button class="action-button form-button btn text-uppercase mt-1" type="button" name="register" (click)="login()">
              {{'header.menuItems.signIn' | translate}}
            </button>
          </div>
          <div *ngIf="(authService.signedInChanged$ | async)">
            <form [formGroup]="registerTeamForm" (ngSubmit)="register()">
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="newTeam" formControlName="newTeam">
                <label class="form-check-label" for="newTeam">{{'event.newTeam' | translate}}</label>
              </div>
              <div class="form-group" *ngIf="!registerTeamForm.controls.newTeam.value">
                <label class="form-label" for="team">{{'global.team' | translate}}</label>
                <select id="team" class="form-select" required formControlName="teamId" [ngClass]="registerTeamForm.controls.teamId.value > -1 ? 'is-valid' : 'is-invalid'">
                  <option [value]="-1" selected disabled hidden></option>
                  <option *ngFor="let team of (teams$ | async)" value="{{team.id}}">{{team.name}} - {{'global.playersCategoryItem.' + team.playersCategory | translate}}</option>
                </select>
              </div>
              <div class="form-group"  *ngIf="registerTeamForm.controls.newTeam.value">
                <label for="teamName">{{'event.teamName' | translate}}</label>
                <input id="teamName" class="form-control" formControlName="teamName" type="text" required [ngClass]="registerTeamForm.controls.teamName.value ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="playersCategory">{{'global.playersCategory' | translate}}</label>
                <select id="playersCategory" class="form-select" formControlName="playersCategory" required [ngClass]="registerTeamForm.controls.playersCategory.valid ? 'is-valid' : 'is-invalid'">
                  <option [value]="-1" selected disabled hidden></option>
                  <option value="{{sportEvent.playersCategory}}" selected>{{'global.playersCategoryItem.' + sportEvent.playersCategory | translate}}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="form-label" for="contactName">{{'event.contactName' | translate}}</label>
                <input id="contactName" class="form-control" formControlName="contactName" type="text" required [ngClass]="registerTeamForm.controls.contactName.valid ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="contactEmail">{{'event.contactEmail' | translate}}</label>
                <input id="contactEmail" class="form-control" formControlName="contactEmail" type="text" required [ngClass]="registerTeamForm.controls.contactEmail.valid ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="contactPhone">{{'event.contactPhone' | translate}}</label>
                <input id="contactPhone" class="form-control" formControlName="contactPhone" type="text" required maxlength="8" [ngClass]="registerTeamForm.controls.contactPhone.valid ? 'is-valid' : 'is-invalid'"/>
              </div>
              <div class="form-group">
                <label>{{'global.timeOfDay' | translate}}</label>
                <div class="form-row me-0 ms-0">
                  <div class="form-group">
                    <div class="form-check form-check-inline" *ngFor="let timeOfDay of getTimOfDay()">
                      <input class="form-check-input" type="checkbox" value="" id="{{timeOfDay}}" formControlName="{{timeOfDay}}">
                      <label class="form-check-label" for="{{timeOfDay}}">{{'global.timeOfDayItem.' + timeOfDay | translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="sportEvent.entryFee && sportEvent.entryFee > 0">
                <div>{{'global.entryFee' | translate}}</div>
                <div>Kr. {{sportEvent.entryFee}}</div>
              </div>
              <div class="form-group">
                <label class="form-label" for="paymentMethod">{{'global.payment' | translate}}</label>
                <select id="paymentMethod" class="form-select" formControlName="paymentMethod" required [ngClass]="registerTeamForm.controls.paymentMethod.valid ? 'is-valid' : 'is-invalid'">
                  <option [value]="-1" selected disabled hidden></option>
                  <option *ngFor="let paymentMethod of sportEvent.paymentMethods" value="{{paymentMethod}}">{{'global.paymentMethodItem.' + paymentMethod | translate}}</option>
                </select>
              </div>
              <div class="form-group" *ngIf="showPayed()">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="payed" formControlName="payed">
                  <label class="form-check-label" for="payed">{{'event.payed' | translate}}</label>
                </div>
              </div>
              <div class="form-section">
                <h5>{{'event.other' | translate}}</h5>
                <div class="form-group">
                  <label class="form-label" for="comment">{{'event.comment' | translate}}</label>
                  <textarea id="comment" class="form-control" formControlName="comment" rows="5"></textarea>
                  <small class="form-text text-muted">
                    {{'events.form.commentLength' | translate}}
                  </small>
                </div>
              </div>
              <button class="action-button form-button btn text-uppercase" type="submit" name="register" [disabled]="!registerTeamForm.valid || !customValidation()">
                {{'event.registerTeam' | translate}}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
