import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService, ChangePassword } from 'shared';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { PasswordValidation, UtilService } from '../../../../../eventShop/src/app/services/util.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit{
  public changePasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(undefined, [Validators.required]),
    newPassword: new FormControl(undefined, [Validators.required]),
    repeatPassword: new FormControl(undefined, [Validators.required])
  });

  public passwordValidation: PasswordValidation;

  constructor(private usersService: UsersService, private router: Router, private utilService: UtilService, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.changePasswordForm.markAllAsTouched();
  }

  public changePassword() {
    if (this.changePasswordForm.valid && this.changePasswordForm.controls.newPassword.value == this.changePasswordForm.controls.repeatPassword.value) {
      this.usersService.changePassword(this.changePasswordForm.value as ChangePassword).pipe(first())
        .subscribe(_ => this.router.navigate([Consts.userRoot]), _ => this.modalService.showModal('modal.error', 'modal.wrongPassword'));
    } else {
      if (this.changePasswordForm.controls.newPassword.value != this.changePasswordForm.controls.repeatPassword.value) {
        this.changePasswordForm.controls.newPassword.setErrors({});
        this.changePasswordForm.controls.repeatPassword.setErrors({})
      }
      this.changePasswordForm.markAllAsTouched();
    }
  }

  public validatePassword() {
    this.passwordValidation = this.utilService.validatePassword(this.changePasswordForm.controls.newPassword.value);
    if(this.passwordValidation.spacing && this.passwordValidation.special && this.passwordValidation.digit && this.passwordValidation.length && this.passwordValidation.smallLetter && this.passwordValidation.capitalLetter){
      this.changePasswordForm.controls.newPassword.setErrors(null);
    }
    else{
      this.changePasswordForm.controls.newPassword.setErrors({});
    }
  }

  public validateRepeatedPassword() {
    if (this.changePasswordForm.controls.newPassword.value != this.changePasswordForm.controls.repeatPassword.value) {
      this.changePasswordForm.controls.repeatPassword.setErrors({});
    } else {
      this.changePasswordForm.controls.repeatPassword.setErrors(null);
    }
  }
}
