import { Component, OnInit } from '@angular/core';
import {
  CustomTranslationService,
  EIndoorFootballEventType,
  EPlayersCategory,
  PaginationBaseComponent,
  SportEvents,
  SportEventsSearch,
  SportEventsService,
  SportEvent,
  EIndoorSoccerEventTier
} from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { shareReplay } from 'rxjs/operators';
import { Consts } from '../../consts';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends PaginationBaseComponent implements OnInit {
  public sportEvents$: Observable<SportEvents>;
  public sportEventsSearchForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required])
  });

  constructor(protected router: Router, protected activatedRoute: ActivatedRoute, private sportEventsService: SportEventsService, public customTranslationService: CustomTranslationService) {
    super(router, activatedRoute);
    this.setupFormValues();
  }

  protected setupActivatedRouteSubscription() {
    this.activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
      let nextPage = 1;
      if (params.has('page')) {
        nextPage = Number(params.get('page'));
      }

      if (params.has('indoorFootballEventTypes') && params.get('indoorFootballEventTypes') !== '') {
        const indoorFootballEventTypes = params.get('indoorFootballEventTypes').split(',') as EIndoorFootballEventType[];
        for (let indoorFootballEventType of indoorFootballEventTypes) {
          this.sportEventsSearchForm.controls[indoorFootballEventType].setValue(true);
        }
      }

      if (params.has('playersCategories') && params.get('playersCategories') !== '') {
        const playersCategories = params.get('playersCategories').split(',') as EPlayersCategory[];
        for (let playersCategory of playersCategories) {
          this.sportEventsSearchForm.controls[playersCategory].setValue(true);
        }
      }

      if (params.has('city')) {
        this.sportEventsSearchForm.controls['city'].setValue(params.get('city'));
      }

      if (params.has('name')) {
        this.sportEventsSearchForm.controls['name'].setValue(params.get('name'));
      }

      if (params.has('startDate')&& params.get('startDate') !== '') {
        this.sportEventsSearchForm.controls.startDate.setValue(moment(new Date(params.get('startDate'))).format("YYYY-MM-DD"));
      }

      if (params.has('playersCategories') && params.get('playersCategories') !== '') {
        const playersCategories = params.get('playersCategories').split(',') as EPlayersCategory[];
        for (let playersCategory of playersCategories) {
          this.sportEventsSearchForm.controls[playersCategory].setValue(true);
        }
      }

      if (params.has('indoorSoccerEventTier') && params.get('indoorSoccerEventTier') !== '') {
        const indoorSoccerEventTiers = params.get('indoorSoccerEventTier').split(',') as EIndoorSoccerEventTier[];
        for (let indoorSoccerEventTier of indoorSoccerEventTiers) {
          this.sportEventsSearchForm.controls[indoorSoccerEventTier].setValue(true);
        }
      }

      this.updateItems(nextPage);
    });
  }

  protected updateItems(page: number) {
    this.page = page;
    this.sportEvents$ = this.sportEventsService.getSportEvents(page - 1, this.itemsPerPage, this.createSportEventsSearch()).pipe(shareReplay(1));
    this.subscription?.unsubscribe();
    this.subscription = this.sportEvents$.subscribe(sportEvents => {
      this.totalNumberOfPages = Math.ceil(sportEvents.total / this.itemsPerPage);
    });
  }

  public getPlayerCategories(): string[] {
    return Object.keys(EPlayersCategory);
  }

  public getIndoorFootballEventTypes(): string[] {
    return Object.keys(EIndoorFootballEventType);
  }

  public getIndoorSoccerEventTiers(): string[] {
    return Object.keys(EIndoorSoccerEventTier);
  }

  public createSportEventsSearch(): SportEventsSearch {
    const sportEventsSearch = this.sportEventsSearchForm.value as SportEventsSearch;
    sportEventsSearch.indoorFootballEventTypes = [];
    for (const indoorFootballEventType of this.getIndoorFootballEventTypes()) {
      if (this.sportEventsSearchForm.controls[indoorFootballEventType].value) {
        sportEventsSearch.indoorFootballEventTypes.push(indoorFootballEventType as EIndoorFootballEventType);
      }
    }

    sportEventsSearch.playersCategories = [];
    for (const playersCategory of this.getPlayerCategories()) {
      if (this.sportEventsSearchForm.controls[playersCategory].value) {
        sportEventsSearch.playersCategories.push(playersCategory as EPlayersCategory);
      }
    }

    sportEventsSearch.indoorSoccerEventTiers = [];
    for (const indoorSoccerEventTier of this.getIndoorSoccerEventTiers()) {
      if (this.sportEventsSearchForm.controls[indoorSoccerEventTier].value) {
        sportEventsSearch.indoorSoccerEventTiers.push(indoorSoccerEventTier as EIndoorSoccerEventTier);
      }
    }

    return sportEventsSearch;
  }

  public async search() {
    const sportEventsSearch = this.createSportEventsSearch();
    const queryParams = {
      name: sportEventsSearch.name,
      city: sportEventsSearch.city,
      startDate: sportEventsSearch.startDate,
      indoorFootballEventTypes: sportEventsSearch.indoorFootballEventTypes.join(','),
      playersCategories: sportEventsSearch.playersCategories.join(','),
      indoorSoccerEventTiers: sportEventsSearch.indoorSoccerEventTiers.join(',')
    }
    await this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  public reset() {
    this.sportEventsSearchForm.reset();
    this.setupFormValues();
  }

  private setupFormValues() {
    for (const indoorFootballEventType of this.getIndoorFootballEventTypes()) {
      this.sportEventsSearchForm.addControl(indoorFootballEventType, new FormControl(false));
    }

    for (const playersCategory of this.getPlayerCategories()) {
      this.sportEventsSearchForm.addControl(playersCategory, new FormControl(false));
    }

    for (const indoorSoccerEventTier of this.getIndoorSoccerEventTiers()) {
      this.sportEventsSearchForm.addControl(indoorSoccerEventTier, new FormControl(false));
    }

    this.sportEventsSearchForm.controls.startDate.setValue(moment(new Date()).format("YYYY-MM-DD"));
  }

  public getEventRoute() {
    return `/${Consts.events}`;
  }

  public isNormalEventTier(sportEvent: SportEvent):boolean{
    return sportEvent.indoorSoccerEventTier == EIndoorSoccerEventTier.Normal
  }
}
