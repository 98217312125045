<div class="top-header-margin pt-5 pb-5">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="form">
          <h2 class="title text-center">{{getHeaderText() | translate}}</h2>
          <form (submit)="updateNewsCategory()" [formGroup]="newsCategoryForm">
            <div class="form-group">
              <label class="form-label" for="categoryName">{{'admin.newsCategories.name' | translate}}</label>
              <input id="categoryName" class="form-control" formControlName="categoryName"/>
            </div>
            <div class="form-group">
              <button class="user-input-button rounded text-uppercase" [disabled]="!newsCategoryForm.valid" type="submit" name="submitButton">
                {{getButtonText() | translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
