<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="inner_wrapper">

        <div class="error-box">
          <p class="error-type">404</p>
        </div>
        <p class="error-text">{{'notFound.title' | translate}}</p>
        <div class="content">
          {{'notFound.message' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
