import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Team, TeamsService } from 'shared';
import { Consts } from '../../consts';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-user-teams',
  templateUrl: './user-teams.component.html',
  styleUrls: ['./user-teams.component.scss']
})
export class UserTeamsComponent implements OnInit {
  public teams$: Observable<Team[]> = of([]);

  constructor(private teamsService: TeamsService, private router: Router, private modalService: ModalService, private toastService: ToastService) {
    this.teams$ = this.teamsService.getTeamsForUser();
  }

  ngOnInit(): void {
  }

  public async getCreateTeamRoute(teamId: number) {
    await this.router.navigate([Consts.teamAdminRootRoute, Consts.teamAdminCreateEditTeamRoute, teamId]);
  }

  public async getAddAminRoute(teamId: number) {
    await this.router.navigate([Consts.teamAdminRootRoute, Consts.teamAdminAddAdminRoute, teamId]);
  }

  public async deactivateTeam(teamId: number){
    await this.modalService.showConfirmation('teamAdmin.team.deleteTitle', 'teamAdmin.team.deleteContent')
      .subscribe(ok => {
        if (ok) {
          this.teamsService.deactivateTeam(teamId).pipe(first()).subscribe(_ => {
              this.toastService.showToast('teamAdmin.team.deletedTitle', 'teamAdmin.team.deletedContent');
              this.teams$ = this.teamsService.getTeamsForUser();
            }
            , error => {
              this.modalService.showModal('modal.error', 'admin.newsCategories.deletedContentError');
            });
        }
      });
  }

  public getCreateNewTeam(): string {
    return `/${Consts.teamAdminRootRoute}/${Consts.teamAdminCreateEditTeamRoute}`;
  }
}
