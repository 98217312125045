import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewsItems, NewsService, PaginationBaseComponent } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent extends PaginationBaseComponent implements OnInit {
  public newsItems$: Observable<NewsItems>;

  constructor(private newsService: NewsService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router) {
    super(router, activatedRoute);
  }

  protected updateItems(page: number) {
    this.page = page;
    this.newsItems$ = this.newsService.getNews(page - 1, this.itemsPerPage).pipe(shareReplay(1));
    this.subscription?.unsubscribe();
    this.subscription = this.newsItems$.subscribe(newsItems => {
      this.totalNumberOfPages = Math.ceil(newsItems.total / this.itemsPerPage);
    });
  }

  public async getNextPage(page: number) {
    await this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {page}});
  }

  public async prevPage(page: number) {
    await this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {page}});
  }

  public async gotToPage(page: number) {
    await this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {page}});
  }
}
