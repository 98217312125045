<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h1 class="header">{{ 'rankings.rankings' | translate }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col" class="small-width">{{ 'rankings.position' | translate }}</th>
          <th scope="col" class="xl-width">{{ 'rankings.team' | translate }}</th>
          <th scope="col" class="large-width">{{ 'rankings.points' | translate }}</th>
          <th scope="col" class="large-width">{{ 'rankings.pointsLastSevenDays' | translate }}</th>
          <th scope="col" class="medium-width">{{ 'rankings.diff' | translate }}</th>
<!--          <th scope="col" class="small-width">{{ 'rankings.profil' | translate }}</th>-->
        </tr>
        </thead>
        <tbody>
        <tr colspan="2" *ngFor="let ranking of (rankings$ | async)?.rankings, let i = index">
          <th scope="row" class="small-width" [ngClass]="[i < 9 ? 'qualified' : '', i === 9 ? 'separator-border' : '']">
            {{ ranking.placement }}
          </th>
          <td class="xl-width" [ngClass]="i === 9 ? 'separator-border' : ''">
            {{ ranking.deactivated ? ('global.deactivatedTeam' | translate) : ranking.name }}
          </td>
          <td class="large-width" [ngClass]="i === 9 ? 'separator-border' : ''">
            {{ ranking.points }}
          </td>
          <td class="large-width" [ngClass]="i === 9 ? 'separator-border' : ''">
            {{ ranking.pointsLastSevenDays }}
          </td>
          <td class="small-width" [ngClass]="i === 9 ? 'separator-border' : ''">
            {{ ranking.placementDiff }}
          </td>
<!--          <td class="medium-width" [ngClass]="i === 9 ? 'separator-border' : ''">-->
<!--&lt;!&ndash;            <div class="d-flex justify-content-center">&ndash;&gt;-->
<!--              <a [routerLink]="['/community', ranking.ownerId]">-->
<!--                <img src="./assets/teams/team.png" alt="team-image" class="team-image">-->
<!--              </a>-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
