<div class="team-container">
  <div class="container-fluid ps-0 pe-0">
    <div class="row">
      <div class="col-md-3">
        <div class="team-image text-center">
          <a [routerLink]="[getTeamRoute(), team.id]">
            <img *ngIf="team.logo" src="{{team.logo}}" alt="">
            <img *ngIf="!team.logo" src="./assets/teams/team.png" alt="">
          </a>
        </div>
      </div>
      <div class="col-md-9">
        <div class="item-header">
          <h5>
            <a [routerLink]="[getTeamRoute(), team.id]">{{team.name}}</a>
          </h5>
          <p>{{'team.points'| translate}} {{team.points}}</p>
          <p>{{'team.numOfPlayers'| translate}} {{team.players.length}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
