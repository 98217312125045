import { Component, OnInit } from '@angular/core';
import { SportEventsService, Rankings } from 'shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.scss']
})
export class RankingsComponent implements OnInit {
  public rankings$: Observable<Rankings>;

  constructor(private sportEventsService: SportEventsService) {
    this.rankings$ = this.sportEventsService.getRankings();
  }

  ngOnInit(): void {
  }

}
