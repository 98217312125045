import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'eventShop-pagination-component',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() public totalNumberOfPages: number;
  @Output() public nextPageEvent = new EventEmitter<number>();
  @Output() public prevPageEvent = new EventEmitter<number>();
  @Output() public goToPageEvent = new EventEmitter<number>();
  @Input() public currentPage = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

  public prevPageNumber(): number {
    return this.currentPage - 1;
  }

  public prevPage() {
    this.prevPageEvent.emit(this.currentPage - 1);
  }

  public nextPageNumber(): number {
    return this.currentPage + 1;
  }

  public nextPage() {
    this.prevPageEvent.emit(this.currentPage + 1);
  }

  public showPrevButton(): boolean {
    return this.currentPage > 1;
  }

  public showNextButton(): boolean {
    return this.currentPage < this.totalNumberOfPages;
  }

  public goToPage(page: number) {
    this.goToPageEvent.emit(page);
  }
}
