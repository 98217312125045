import { Component, HostListener, OnInit } from '@angular/core';
import { NewsService } from 'shared';
import { Observable } from 'rxjs';
import { NewsItems } from 'shared';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public newsItems$: Observable<NewsItems>
  public showGoToTop = false;

  constructor(private newsService: NewsService) {
    this.newsItems$ = this.newsService.getNews(0, 3);
  }

  ngOnInit(): void {
    this.showGoToTop = window.scrollY > 0;
  }


  @HostListener('window:scroll', ['$event'])
  onScroll($event) {
    this.showGoToTop = window.scrollY > 0;
  }

  public goToTop() {
    window.scrollTo(window.scrollX, 0);
  }
}
