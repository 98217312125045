import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Toast } from '../interfaces/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private subject = new Subject<Toast>();

  constructor() {
  }

  public toasts(): Observable<Toast> {
    return this.subject.asObservable();
  }

  public showToast(title: string, message: string) {
    const toast = {
      title,
      message
    } as Toast;
    this.subject.next(toast);
  }
}
