import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class BreakpointObserverService {
  public max1280$ = this.breakpointObserver.observe(['(max-width: 1280px)']);
  public max992$ = this.breakpointObserver.observe(['(max-width: 992px)']);
  public tableSmaller$ = this.breakpointObserver.observe(['(max-width: 991px)']);
  public aboveiPad$ = this.breakpointObserver.observe(['(min-width: 769px)']);
  public ipad$ = this.breakpointObserver.observe(['(max-width: 768px)']);
  public belowiPad$ = this.breakpointObserver.observe(['(max-width: 767px)']);
  public max168$ = this.breakpointObserver.observe(['(max-width: 1068px)']);
  public max890$ = this.breakpointObserver.observe(['(max-width: 890px)']);

  constructor(private breakpointObserver: BreakpointObserver) {
  }
}
