import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, EventShopUser, UsersService } from 'shared';
import { Consts } from '../../consts';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  public userForm: FormGroup = new FormGroup({
    username: new FormControl(undefined, []),
    firstName: new FormControl(undefined, [Validators.required]),
    lastName: new FormControl(undefined, [Validators.required]),
    phoneNumber: new FormControl(undefined, [Validators.pattern("[0-9]{8}")]),
    email: new FormControl(undefined, [Validators.required, Validators.email])
  });
  private subscription = new Subscription();

  constructor(private userService: UsersService, private authService: AuthService, private modalService: ModalService, private router: Router, private toastService: ToastService) {
    this.subscription.add(this.authService.userChanged$.subscribe(u => {
      this.userForm.patchValue(u);
    }, error => {
      this.modalService.showModal('modal.error', 'user.error')
        .subscribe(_ => this.router.navigate([Consts.userRoot]));
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public updateUserInfo() {
    this.userService.updateUser(this.userForm.value as EventShopUser).pipe(first())
      .subscribe(_ => {
        this.authService.fetchUserInfo()
        this.toastService.showToast('user.updatedTitle', 'user.updatedContent');
        this.router.navigate([Consts.userRoot]);
      }, error => {
        this.modalService.showModal('modal.error', 'user.updatedContentError');
      });
  }
}
