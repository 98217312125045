import { Component, OnInit } from '@angular/core';
import { CustomTranslationService, PaginationBaseComponent, SportEvents, SportEventsService } from 'shared';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Consts } from '../../consts';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { first, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-user-events',
  templateUrl: './user-events.component.html',
  styleUrls: ['./user-events.component.scss']
})
export class UserEventsComponent extends PaginationBaseComponent implements OnInit {
  public sportEvents$: Observable<SportEvents>;

  constructor(private sportEventsService: SportEventsService,
              protected  router: Router,
              protected  activatedRoute: ActivatedRoute,
              public customTranslationService: CustomTranslationService,
              private modalService: ModalService,
              private toastService: ToastService) {
    super(router, activatedRoute);
  }

  protected updateItems(page: number) {
    this.page = page;
    this.sportEvents$ = this.sportEventsService.getSportEventsForUser(page - 1, this.itemsPerPage).pipe(shareReplay(1));
    this.subscription?.unsubscribe();
    this.subscription = this.sportEvents$.subscribe(newsItems => {
      this.totalNumberOfPages = Math.ceil(newsItems.total / this.itemsPerPage);
    });
  }

  public deleteEvent(id: number) {
    this.modalService.showConfirmation('user.eventsList.deleteTitle', 'user.eventsList.deleteContent')
      .subscribe(ok => {
        if (ok) {
          this.sportEventsService.deleteSportEvent(id).pipe(first()).subscribe(_ => {
              this.toastService.showToast('user.eventsList.deletedTitle', 'user.eventsList.deletedContent');
              this.updateItems(this.page);
            }
            , error => {
              this.modalService.showModal('modal.error', 'user.eventsList.deletedContentError');
            });
        }
      });
  }

  public async editEvent(id: number) {
    await this.router.navigate([Consts.userRoot, Consts.createEditEvent, id]);
  }

  public async addRemoveAdmins(id: number) {
    await this.router.navigate([Consts.userRoot, Consts.addRemoveEventAdmin, id]);
  }

  public getEventRoute() {
    return `/${Consts.userRoot}/${Consts.userEvent}`;
  }
}
