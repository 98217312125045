<div class="container-fluid">
  <div class="row" *ngFor="let team of (teams$ | async) as teams">
    <div class="col-md-4 pt-2 event-top-border mb-4">
      <div class="item-header">
        <h5>{{ 'global.team' | translate }}</h5>
      </div>
      <div>
        <table class="table custom-table">
          <tbody>
          <tr>
            <td colspan="2">
              <img *ngIf="team.logo" src="{{team.logo}}" alt="" class="team-image">
              <img *ngIf="!team.logo" src="./assets/teams/team.png" alt="" class="team-image">
            </td>
          </tr>
          <tr>
            <td><strong>{{ 'team.name' | translate }}:</strong></td>
            <td>{{ team.name }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'global.playersCategory' | translate }}:</strong></td>
            <td>{{ 'global.playersCategoryItem.' + team.playersCategory | translate }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'global.address' | translate }}:</strong></td>
            <td>{{ team.location?.streetOne }}, {{ team.location?.streetTwo }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'global.postalCodeCity' | translate }}:</strong></td>
            <td>{{ team.location?.postalCode }} {{ team.location?.city }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-4 event-top-border pt-2">
      <div ngbDropdown class="dropdown">
        <button ngbDropdownToggle class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ 'admin.news.options' | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="getCreateTeamRoute(team.id)">{{ 'teamAdmin.team.edit' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="deactivateTeam(team.id)">{{ 'teamAdmin.team.delete' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="getAddAminRoute(team.id)">{{ 'teamAdmin.team.addAdmin' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <button class="btn action-button form-button text-uppercase" [routerLink]="getCreateNewTeam()">
      {{ 'teamAdmin.team.create' | translate }}
    </button>
  </div>
</div>
