import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Consts } from '../../consts';
import { NewsCategoriesService, NewsCategory } from 'shared';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'app-admin-create-edit-news-category',
  templateUrl: './admin-create-edit-news-category.component.html',
  styleUrls: ['./admin-create-edit-news-category.component.scss']
})
export class AdminCreateEditNewsCategoryComponent implements OnInit {
  public newsCategoryForm: FormGroup = new FormGroup({
    categoryName: new FormControl(undefined, [Validators.required])
  });

  private readonly creating: boolean;
  private readonly id: number;

  constructor(private activatedRoute: ActivatedRoute,
              private newsCategoriesService: NewsCategoriesService,
              private router: Router,
              private modalService: ModalService,
              private toastService: ToastService) {
    const params = this.activatedRoute.snapshot.params;
    this.creating = true;
    if (params.newsCategoryId) {
      this.id = params.newsCategoryId as number;
      this.creating = false;
      this.newsCategoriesService.getNewsCategory(this.id).pipe(first()).subscribe(newsCategory => {
        this.newsCategoryForm.patchValue(newsCategory);
      }, error => {
        this.modalService.showModal('modal.error', 'admin.newsCategories.notFound').pipe(first())
          .subscribe(_ => this.router.navigate([Consts.adminRootRoute, Consts.adminNewsCategoriesRoute]));
      });
    }
  }

  ngOnInit() {
  }

  public getButtonText(): string {
    if (this.creating) {
      return 'admin.newsCategories.create';
    } else {
      return 'admin.newsCategories.update';
    }
  }

  public getHeaderText(): string {
    if (this.creating) {
      return 'admin.newsCategories.createNewsCategory';
    } else {
      return 'admin.newsCategories.updateNewsCategory';
    }
  }

  public updateNewsCategory() {
    if (this.creating) {
      this.newsCategoriesService.createNewsCategory(this.newsCategoryForm.value as NewsCategory).pipe(first())
        .subscribe(_ => this.router.navigate([Consts.adminRootRoute, Consts.adminNewsCategoriesRoute])
          , error => this.modalService.showModal('modal.error', 'admin.newsCategories.createNewsCategoryError'));
    } else {
      this.newsCategoriesService.updateNewsCategory(this.id, this.newsCategoryForm.value as NewsCategory).pipe(first())
        .subscribe(_ => {
          this.toastService.showToast('admin.newsCategories.updatedTitle', 'admin.newsCategories.updatedContent');
          this.router.navigate([Consts.adminRootRoute, Consts.adminNewsCategoriesRoute]);
        }, error => {
          this.modalService.showModal('modal.error', 'admin.newsCategories.updatedContentError');
        });
    }
  }
}
