<div class="row">
  <nav aria-label="...">
    <ul class="pagination justify-content-center">
      <li class="page-item" *ngIf="showPrevButton()">
        <a class="page-link" tabindex="-1" aria-disabled="true" (click)="prevPage()">&laquo;</a>
      </li>
      <li *ngIf="showPrevButton()" class="page-item">
        <a class="page-link" (click)="goToPage(prevPageNumber())">{{prevPageNumber()}}</a></li>
      <li class="page-item active" aria-current="page">
        <a class="page-link">{{currentPage}}<span class="visually-hidden">(current)</span></a>
      </li>
      <li *ngIf="showNextButton()" class="page-item">
        <a class="page-link" (click)="goToPage(nextPageNumber())">{{nextPageNumber()}}</a></li>
      <li class="page-item" *ngIf="showNextButton()">
        <a class="page-link" (click)="nextPage()">&raquo;</a>
      </li>
    </ul>
  </nav>
</div>
