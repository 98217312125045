<div class="top-header-margin pt-5 pb-5">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <h2 class="title text-center">{{getHeaderText() | translate}}</h2>
        <form (submit)="updateNews()" [formGroup]="newsForm">
          <div class="form-group">
            <label class="form-label" for="title">{{'admin.news.title' | translate}}</label>
            <input id="title" class="form-control" formControlName="title"/>
          </div>
          <div class="form-group editor-container">
            <label class="form-label" for="content">{{'admin.news.content' | translate}}</label>
            <div class="editor">
              <quill-editor id="content" formControlName="content"  [sanitize]="true" ></quill-editor>
            </div>
          </div>
          <div class="form-group">
            <button class="user-input-button rounded text-uppercase" [disabled]="!newsForm.valid" type="submit" name="submitButton">
              {{getButtonText() | translate}}
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-2">
        <form>
          <h3 class="title text-center">{{'admin.news.categories' | translate}}</h3>
          <div class="form-group form-check" *ngFor="let newsCategory of newsCategories$ | async; let i = index">
            <input type="checkbox" class="form-check-input" id="category{{i}}" (change)="onCategoryChange(newsCategory, $event)" [checked]="isSelected(newsCategory)">
            <label class="form-check-label" for="category{{i}}">{{newsCategory.categoryName}}</label>
          </div>
        </form>
        <app-file-selection-component [imageUrl]="this.imageUrl" (file)="onFileChanged($event)"></app-file-selection-component>
      </div>
    </div>
  </div>
</div>
