import { RouterModule, Routes } from '@angular/router';
import { Consts } from './consts';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { TeamsGuard } from './guards/teams-guard.service';
import { TeamAdminComponent } from './components/team-admin/team-admin.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminNewsComponent } from './components/admin-news/admin-news.component';
import { AdminCreateEditNewsComponent } from './components/admin-create-edit-news/admin-create-edit-news.component';
import { AdminNewsCategoriesComponent } from './components/admin-news-categories/admin-news-categories.component';
import { AdminCreateEditNewsCategoryComponent } from './components/admin-create-edit-news-category/admin-create-edit-news-category.component';
import { TeamAdminTeamComponent } from './components/team-admin-team/team-admin-team.component';
import { TeamAdminCreateEditTeamComponent } from './components/team-admin-create-edit-team/team-admin-create-edit-team.component';
import { NewsComponent } from './components/news/news.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { CategoryNewsComponent } from './components/category-news/category-news.component';
import { EventsComponent } from './components/events/events.component';
import { EventComponent } from './components/event/event.component';
import { CommunityComponent } from './components/community/community.component';
import { TeamComponent } from './components/team/team.component';
import { SignedInGuard, AdminGuard } from 'shared';
import { UserComponent } from './components/user/user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { CreateEditEventComponent } from './components/create-edit-event/create-edit-event.component';
import { UserEventsComponent } from './components/user-events/user-events.component';
import { RankingsComponent } from './components/rankings/rankings.component';
import { UserTeamsComponent } from './components/user-teams/user-teams.component';
import { AdminAddTeamPointsComponent } from './components/admin-add-team-points/admin-add-team-points.component';
import { UserSignUpComponent } from './components/user-sign-up/user-sign-up.component';
import { UserChangePasswordComponent } from './components/user-change-password/user-change-password.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/reset-password-link.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AddRemoveEventAdminsComponent } from './components/add-remove-event-admins/add-remove-event-admins.component';
import { ContactComponent } from './components/contact/contact.component';
import { UserEventComponent } from './components/user-event/user-event.component';
import { TeamAdminAddAdminComponent } from './components/team-admin-add-admin/team-admin-add-admin.component';
import { LivescoreComponent } from './components/livescore/livescore.component';
import { UserSignInComponent } from './components/user-sign-in/user-sign-in.component';


export const routes: Routes = [
  // {
  //   path: Consts.organizeRootRoute, canActivate: [SignedInGuard, CompanyGuard], children: [
  //     {path: '', component: OrganizerComponent}
  //   ]
  // },
  {
    path: Consts.teamAdminRootRoute, canActivate: [SignedInGuard], children: [
      {path: '', component: TeamAdminComponent},
      {path: Consts.teamAdminTeamsRoute, component: TeamAdminTeamComponent, canActivate: [TeamsGuard]},
      {path: Consts.teamAdminCreateEditTeamRoute, component: TeamAdminCreateEditTeamComponent},
      {path: `${Consts.teamAdminCreateEditTeamRoute}/:teamId`, component: TeamAdminCreateEditTeamComponent},
      {path: `${Consts.teamAdminAddAdminRoute}/:teamId`, component: TeamAdminAddAdminComponent}
    ]
  },
  {
    path: Consts.adminRootRoute, canActivate: [SignedInGuard, AdminGuard], children: [
      {path: '', component: AdminComponent},
      {path: Consts.adminNewsRoute, component: AdminNewsComponent},
      {path: Consts.adminCreateNewsRoute, component: AdminCreateEditNewsComponent},
      {path: `${Consts.adminCreateNewsRoute}/:newsId`, component: AdminCreateEditNewsComponent},
      {path: Consts.adminNewsCategoriesRoute, component: AdminNewsCategoriesComponent},
      {path: Consts.adminCreateEditNewsCategoryRoute, component: AdminCreateEditNewsCategoryComponent},
      {
        path: `${Consts.adminCreateEditNewsCategoryRoute}/:newsCategoryId`,
        component: AdminCreateEditNewsCategoryComponent
      },
      {path: `${Consts.adminAddTeamPointsRoute}`, component: AdminAddTeamPointsComponent}
    ]
  },
  {
    path: Consts.userRoot, canActivate: [SignedInGuard], children: [
      {path: '', component: UserComponent},
      {path: Consts.editUser, component: EditUserComponent},
      {path: Consts.userEvents, component: UserEventsComponent},
      {path: `${Consts.userEvent}/:eventId`, component: UserEventComponent},
      {path: Consts.userTeams, component: UserTeamsComponent},
      {path: Consts.createEditEvent, component: CreateEditEventComponent},
      {path: `${Consts.createEditEvent}/:eventId`, component: CreateEditEventComponent},
      {path: Consts.userChangePasswordRoute, component: UserChangePasswordComponent},
      {path: `${Consts.addRemoveEventAdmin}/:eventId`, component: AddRemoveEventAdminsComponent},
    ]
  },
  {path: Consts.userSignUpRoute, component: UserSignUpComponent},
  {path: Consts.userSignIn, component: UserSignInComponent},
  {path: Consts.userChangePasswordRoute, component: UserChangePasswordComponent},
  {path: Consts.userResetPasswordLinkRoute, component: ResetPasswordLinkComponent},
  {path: Consts.userResetPasswordRoute, component: ResetPasswordComponent},

  {path: `${Consts.newsRoute}/:newsId`, component: NewsComponent},
  {path: Consts.newsRoute, component: NewsListComponent},
  {path: `${Consts.categoryNewsRoute}/:categoryId`, component: CategoryNewsComponent},
  {path: `${Consts.events}/:eventId`, component: EventComponent},
  {path: Consts.events, component: EventsComponent},
  {path: `${Consts.team}/:teamId`, component: TeamComponent},
  {path: Consts.community, component: CommunityComponent},
  {path: Consts.rankings, component: RankingsComponent},
  {path: Consts.contact, component: ContactComponent},
  {path: Consts.livescore, component: LivescoreComponent},
  {path: Consts.notFound, component: NotFoundComponent, pathMatch: 'full'},
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: '**', redirectTo: Consts.notFound, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', scrollOffset: [0,0] })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
