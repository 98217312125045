import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSerializer } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignedInGuard implements CanActivate {
  constructor(protected authService: AuthService, protected router: Router, private serializer: UrlSerializer) {
  }

  public canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let authenticated = this.authService.isAuthenticated();
    if (!authenticated) {
      this.router.navigate(['/user-sign-in'], {
        queryParams: {
          queryParams: {
            returnUrl: state.url
          }
        }
      });
    }

    return authenticated;
  }
}
