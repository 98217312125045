import { Component, OnInit } from '@angular/core';
import { CustomTranslationService, News, NewsService } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  public news$: Observable<News>;

  constructor(private newsService: NewsService, private activatedRoute: ActivatedRoute, private modalService: ModalService, private router: Router, public customTranslationService: CustomTranslationService) {
    const params = this.activatedRoute.snapshot.params;
    if (params.newsId) {
      this.news$ = this.newsService.getNewsItem(params.newsId as number).pipe(
        catchError(err => {
          this.modalService.showModal('modal.error', 'global.newsNotFound')
            .subscribe(_ => this.router.navigate(['/']))
          return of<News>();
        }));
    }
  }

  ngOnInit(): void {
  }
}
