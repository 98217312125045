import { Injectable } from '@angular/core';
import { Location } from 'shared';
import { SponsorEventSummery } from '../models/sponsor-event-summery';
import * as moment from 'moment';
import { Rank } from '../models/rank';
import { SponsorRunUserUnitTypeSummaryPerSponsor } from '../models/sponsor-run-user-unit-type-summary-per-sponsor';
import { SponsorRunUserSummaryDto } from '../models/SponsorRunUserSummaryDto';
import { GetSponsorEventCompanyOverviewResponse } from '../models/GetSponsorEventCompanyOverviewResponse';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  public getDateFormat(): string {
    return 'dd/MM/yyyy';
  }

  public getDateTimeFormat(): string {
    return 'dd/MM/yyyy - HH:mm:ss';
  }

  public formatAddress(address: Location): string {
    if (address) {
      let addressString = `${address?.streetOne},`;
      if (address?.streetTwo) {
        addressString += ` ${address?.streetTwo}`;
      }

      addressString += ` ${address?.postalCode} ${address?.city}`;
      return addressString;
    }

    return '';
  }

  public dateBeforeNow(date: Date): boolean {
    return moment(date).isBefore(moment());
  }

  public dateAfterNow(date: Date): boolean {
    return moment(date).isAfter(moment());
  }

  public getCapSumSponsorRank(sponsorRank: Rank, sponsorEventCompanyOverviewResponse: GetSponsorEventCompanyOverviewResponse): number {
    const users: SponsorRunUserUnitTypeSummaryPerSponsor[] = [];
    for (let sponsorRunUserSummary of sponsorEventCompanyOverviewResponse.sponsorRunUserSummaries) {
      for (let userUnitTypeSummary of sponsorRunUserSummary.userUnitTypeSummaries) {
        users.push(...userUnitTypeSummary.summaryPerSponsorList.filter(s => s.sponsorId == sponsorRank.sponsorId));
      }
    }
    let sum = 0;
    for (let user of users) {
      sum += user.sponsorCap;
    }
    return sum;
  }

  public getSponsorMaxPayment(sponsorRank: Rank): number {
    let sum = -1;
    const users: SponsorRunUserUnitTypeSummaryPerSponsor[] = [];
    if (sponsorRank.sponsorSummery.length > 1) {
      for (let sponsorSummery of sponsorRank.sponsorSummery) {
        if (sponsorSummery.maxPayment) {
          sum = Math.max(sum, sponsorSummery.maxPayment);
        }
      }

      if (sum > -1) {
        return sum;
      }
      return null;
    } else {
      return sponsorRank.sponsorSummery[0].maxPayment;
    }
  }

  public getCapSumSponsorRunUserSummary(sponsorRunUserSummary: SponsorRunUserSummaryDto): number {
    if (sponsorRunUserSummary.maxUserPayment > 0) {
      return sponsorRunUserSummary.maxUserPayment - sponsorRunUserSummary.totalUserMoneyToBePayed;
    }
    return 0;
  }

  public validatePassword(password: string): PasswordValidation {
    return {
      length: password.length >= 8,
      digit: new RegExp('[0-9]').test(password),
      capitalLetter: new RegExp('[A-Z]').test(password),
      smallLetter: new RegExp('[a-z]').test(password),
      special: new RegExp('[!@#$%^&*(),.?":{}|<>]').test(password),
      spacing: !new RegExp('[ ]').test(password),
    };
  }

  public passwordValid(passwordValidation: PasswordValidation): boolean {
    if (!passwordValidation) {
      return false;
    }

    return passwordValidation.length && passwordValidation.digit && passwordValidation.capitalLetter && passwordValidation.smallLetter && passwordValidation.special && passwordValidation.special && passwordValidation.spacing;
  }

  public usernameValid(usernameValidation: UsernameValidation): boolean {
    if (!usernameValidation) {
      return false;
    }
    return usernameValidation.special && usernameValidation.spacing;
  }

  public validateUsername(username: string): UsernameValidation {
    return {
      special: !new RegExp('[!@#$%^&*(),.?":{}|<>]').test(username),
      spacing: !new RegExp('[ ]').test(username)
    };
  }

  public getPayedClassFromSponsorRunUser(sponsorRunUserSummary: SponsorRunUserSummaryDto): string {
    for (const userUnitTypeSummary of sponsorRunUserSummary.userUnitTypeSummaries) {
      const allPayed = userUnitTypeSummary.summaryPerSponsorList.every(s => s.isPayed);
      const somePayed = userUnitTypeSummary.summaryPerSponsorList.some(s => s.isPayed);
      if (!allPayed && !somePayed) {
        return 'none-payed';
      }

      if (somePayed) {
        return 'some-payed';
      }
    }

    return 'payed';
  }

  public getPayedClassFromSponsor(sponsorRunUserUnitTypeSummaryPerSponsor: SponsorRunUserUnitTypeSummaryPerSponsor): string {
    if (!sponsorRunUserUnitTypeSummaryPerSponsor.isPayed) {
      return 'none-payed';
    }

    return 'payed';
  }
}

export interface UsernameValidation {
  special: boolean;
  spacing: boolean;
}

export interface PasswordValidation {
  length: boolean;
  digit: boolean;
  capitalLetter: boolean;
  smallLetter: boolean;
  special: boolean;
  spacing: boolean;
}
