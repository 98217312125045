import { NgModule } from '@angular/core';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationBaseComponent } from './components/pagination-base/pagination-base.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    PaginationComponent,
    PaginationBaseComponent,
    TabComponent,
    TabsComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
  exports: [
    PaginationComponent,
    PaginationBaseComponent,
    TabComponent,
    TabsComponent,
    LoaderComponent
  ]
})
export class SharedModule {
}
