import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  public validatePassword(password: string): PasswordValidation {
    return {
      length: password.length >= 8,
      digit: new RegExp('[0-9]').test(password),
      capitalLetter: new RegExp('[A-Z]').test(password),
      smallLetter: new RegExp('[a-z]').test(password),
      special: new RegExp('[!@#$%^&*(),.?":{}|<>]').test(password),
      spacing: !new RegExp('[ ]').test(password),
    }
  }

  public passwordValid(passwordValidation: PasswordValidation): boolean{
    if(!passwordValidation){
      return false;
    }

    return passwordValidation.length && passwordValidation.digit && passwordValidation.capitalLetter && passwordValidation.smallLetter && passwordValidation.special && passwordValidation.special && passwordValidation.spacing;
  }

  public usernameValid(usernameValidation: UsernameValidation): boolean {
    if (!usernameValidation) {
      return false;
    }
    return usernameValidation.special && usernameValidation.spacing;
  }

  public validateUsername(username: string): UsernameValidation {
    return {
      special: !new RegExp('[!@#$%^&*(),.?":{}|<>]').test(username),
      spacing: !new RegExp('[ ]').test(username)
    }
  }

  public dateBeforeNow(date: Date): boolean {
    return moment(date).isBefore(moment());
  }

  public dateAfterNow(date: Date): boolean {
    return moment(date).isAfter(moment());
  }
}

export interface UsernameValidation {
  special: boolean;
  spacing: boolean;
}

export interface PasswordValidation {
  length: boolean;
  digit: boolean;
  capitalLetter: boolean;
  smallLetter: boolean;
  special: boolean;
  spacing: boolean;
}
