import { Component, OnInit } from '@angular/core';
import { CustomTranslationService, NewsItems, NewsService } from 'shared';
import { Observable } from 'rxjs';
import { Consts } from '../../consts';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public news$: Observable<NewsItems>

  constructor(public newsService: NewsService, public customTranslationService: CustomTranslationService) {
    this.news$ = this.newsService.getNews(0, 10);
  }

  ngOnInit(): void {
  }

  public getNewsRoute(): string {
    return Consts.newsRoute;
  }
}
