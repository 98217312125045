<div class="top-header-margin ">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="item-header">
          <h5>{{'user.userInfo' | translate}}</h5>
        </div>
        <form (submit)="updateUserInfo()" [formGroup]="userForm">
          <div class="form-group">
            <label class="form-label" for="username">{{'user.form.username' | translate}}</label>
            <input id="username" class="form-control" formControlName="username" readonly/>
          </div>
          <div class="form-group">
            <label class="form-label" for="firstName">{{'user.form.firstName' | translate}}</label>
            <input id="firstName" class="form-control" [ngClass]="userForm.controls.firstName.valid ? 'is-valid' : 'is-invalid'" formControlName="firstName" required/>
          </div>
          <div class="form-group">
            <label class="form-label" for="lastName">{{'user.form.lastName' | translate}}</label>
            <input id="lastName" class="form-control" [ngClass]="userForm.controls.lastName.valid ? 'is-valid' : 'is-invalid'" formControlName="lastName" required/>
          </div>
          <div class="form-group">
            <label class="form-label" for="email">{{'user.form.email' | translate}}</label>
            <input id="email" class="form-control" [ngClass]="userForm.controls.email.valid ? 'is-valid' : 'is-invalid'"  formControlName="email" required/>
          </div>
          <div class="form-group">
            <label class="form-label" for="phoneNumber">{{'user.form.phoneNumber' | translate}}</label>
            <input id="phoneNumber" class="form-control" [ngClass]="userForm.controls.phoneNumber.valid ? 'is-valid' : 'is-invalid'" formControlName="phoneNumber" type="text" maxlength="8" required/>
          </div>
          <div class="form-group">
            <button class="form-button btn text-uppercase" [disabled]="!userForm.valid" type="submit" name="submitButton">
              {{'generalForm.update' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
