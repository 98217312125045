import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LivescoreItem } from '../../interfaces/livescore-item';
import { environment } from '../../../environments/environment';
import { filter, first, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-livescore',
  templateUrl: './livescore.component.html',
  styleUrls: ['./livescore.component.scss']
})
export class LivescoreComponent implements OnInit {

  public livescoreItems$: Observable<LivescoreItem[]>;
  public livescoreitems = [
    {
      'name': 'test',
      'startdate': '18-11-2022',
      'enddate': 'test',
      'livescore': 'test',
      'released': true,
      'link': 'test'
    },
    {
      'name': 'test',
      'startdate': '18-11-2024',
      'enddate': 'test',
      'livescore': 'test',
      'released': true,
      'link': 'test'
    },
    {
      'name': 'test',
      'startdate': '18-11-2023',
      'enddate': 'test',
      'livescore': 'test',
      'released': true,
      'link': 'test'
    }
  ]

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.livescoreItems$ = this.httpClient.get<LivescoreItem[]>(environment.livescoreUrl)
    // this.livescoreItems$ = of(this.livescoreitems)
      .pipe(map(livescoreItems => livescoreItems.sort((a, b) => {
        return moment(b.startdate, 'DD-MM-YYYY').toDate().getTime() - moment(a.startdate, 'DD-MM-YYYY').toDate().getTime()
      })));
  }
}
