<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h1 class="header">{{'global.news' | translate}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div *ngFor="let news of (newsItems$ | async)?.newsItems">
        <app-news-list-item [news]="news"></app-news-list-item>
      </div>
      <eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="gotToPage($event)" (nextPageEvent)="getNextPage($event)" (prevPageEvent)="prevPage($event)"></eventShop-pagination-component>
    </div>
    <div class="col-md-4">
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>
