import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationBaseComponent, Teams, TeamsService } from 'shared';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent extends PaginationBaseComponent implements OnInit {
  public teams$: Observable<Teams>;

  constructor(private teamsService: TeamsService, protected activatedRoute: ActivatedRoute, protected router: Router) {
    super(router, activatedRoute);
  }

  protected updateItems(page: number) {
    this.page = page;
    this.teams$ = this.teamsService.getTeams(page - 1, this.itemsPerPage).pipe(shareReplay(1));
    this.subscription?.unsubscribe();
    this.subscription = this.teams$.subscribe(teams => {
      this.totalNumberOfPages = Math.ceil(teams.total / this.itemsPerPage);
    });
  }
}
